import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Card, CardContent, Grid, Button, Divider, Box, CircularProgress, IconButton } from '@mui/material';
import axios from 'axios';
import Cookies from 'universal-cookie';
import NavBar from '../../components/navbar';
import { AddShoppingCart, RemoveShoppingCartRounded, RemoveShoppingCartTwoTone, ShoppingCart, ShoppingCartCheckout } from '@mui/icons-material';
import QuantityDropdown from '../../components/quantity';

function CartScreen() {
    const [totalAmount, setTotalAmount] = useState("");
    const [discounted, setDiscounted] = useState("");
    const [showCheckout, setShowCheckout] = useState(false);
    const [emptyCart, setEmptyCart] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cartItems, setCartItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getCartItems();
    }, []);

    const getCartItems = async () => {
        try {
            const cookies = new Cookies();
            const userID = cookies.get('userID');
            const response = await axios.post(process.env.REACT_APP_BASE_URL + `/cart/get-items`, { userID }, { withCredentials: true });
            const result = response.data;
            setTotalAmount(result.totalAmount);
            setDiscounted(result.discounted);
            setCartItems(result.items);
            setEmptyCart(result.items.length !== 0);

            const outOfStock = result.items.some(item => isOutOfStock(item));
            setShowCheckout(!outOfStock);
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('An error occurred while fetching cart items:', error);
            }
        } finally {
            setLoading(false)
        }
    };

    const changeQuantity = async (cartID, value) => {
        try {
            setShowCheckout(false);
            const response = await axios.post(process.env.REACT_APP_BASE_URL + `/cart/change-item-quantity`, { cartID, qty: value.toString() }, { withCredentials: true });
            if (response.data.status) {
                getCartItems();
            }
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('An error occurred while changing item quantity:', error);
            }
        }
    };

    const removeItemFromCart = async (cartID) => {
        try {
            setShowCheckout(false);
            const response = await axios.delete(process.env.REACT_APP_BASE_URL + `/cart/remove-item`, {
                data: { cartID },
                withCredentials: true
            });
            if (response.data.status) {
                getCartItems();
            }
        } catch (error) {
            console.error('An error occurred while removing item from cart:', error);
        }
    };

    const isOutOfStock = (cart) => {
        let maxvalue = 0;
        const selectedSizeItem = cart.productID.sizes.find(sizeItem => sizeItem.sizeTitle === cart.size);

        if (selectedSizeItem) {
            const selectedQuantity = selectedSizeItem.quantity.find(quantity => quantity.color === cart.color);
            if (selectedQuantity) {
                maxvalue = selectedQuantity.qty;
            }
        }

        return maxvalue < cart.quantity;
    };

    const renderQuantitySelector = (cart) => {
        let maxvalue = 0;
        const selectedSizeItem = cart.productID.sizes.find(sizeItem => sizeItem.sizeTitle === cart.size);

        if (selectedSizeItem) {
            const selectedQuantity = selectedSizeItem.quantity.find(quantity => quantity.color === cart.color);
            if (selectedQuantity) {
                maxvalue = selectedQuantity.qty;
            }
        }

        if (maxvalue >= cart.quantity) {
            return (
                <QuantityDropdown
                    maxValue={maxvalue}
                    initialValue={cart.quantity}
                    onChange={(value) => changeQuantity(cart._id, value)}
                />
            );
        } else {
            return <p style={{ color: 'red' }}>Sold Out, Remove this product to proceed</p>;
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <ShoppingCart style={{ marginRight: 6 }} /><Typography variant="h6">Your Shopping Cart</Typography>
                </Toolbar>
            </AppBar>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Container>
                    {emptyCart ? (
                        <div>

                            <Grid container spacing={2} style={{ marginTop: 8 }}>
                                {cartItems && cartItems.map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Card>
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={2} md={2} style={{alignSelf: 'center'}}>
                                                        <img src={item.productID.images[0]} alt={item.productID.title} style={{ maxWidth: '100%', maxHeight: 160 }} />
                                                    </Grid>
                                                    <Grid item xs={6} md={8} style={{ placeSelf: 'center' }}>
                                                        <Typography variant="h6" sx={{ fontSize: { xs: '18px' } }} style={{ marginBottom: 6 }}>{item.productID.title}</Typography>
                                                        <Typography variant="body1" sx={{ fontSize: { xs: '14px' } }} style={{ marginBottom: 6 }}>Size: {item.size} {item.color && `| Color: ${item.color}`}</Typography>
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                                            <Typography variant='h6'>
                                                                ₹{item.productID.finalPrice * item.quantity}
                                                            </Typography>

                                                            <Typography variant="body2" style={{ marginLeft: '6px', textDecoration: 'line-through', color: 'grey', fontSize: '16px' }}>
                                                                ₹{item.productID.actualPrice * item.quantity}
                                                            </Typography>

                                                            <div style={{ marginLeft: '6px', backgroundColor: 'green', padding: '4px 6px', borderRadius: '4px', display: 'inline-block' }}>
                                                                <Typography variant="body1" style={{ color: 'white', fontSize: '12px' }}>
                                                                    {item.productID.discount}% off
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        {renderQuantitySelector(item)}
                                                    </Grid>
                                                    <Grid item xs={3} md={2} style={{ placeSelf: 'center', textAlign: 'right' }}>
                                                        <IconButton variant='outlined' color='error' onClick={() => removeItemFromCart(item._id)}><RemoveShoppingCartRounded /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                ))}
                            </Grid>
                            <Divider style={{ margin: '20px 0' }} />
                            {showCheckout ? (
                                <Container style={{ backgroundColor: 'white', padding: 18 }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="h6">Total: ₹{totalAmount}</Typography>
                                        </Grid>
                                        <Grid item xs={4} container justifyContent="center">
                                            <Typography variant="body1" style={{ fontSize: '16px' }} color={'darkgreen'}>You saved ₹{discounted} on your cart items</Typography>
                                        </Grid>
                                        <Grid item xs={4} container justifyContent="end">
                                            <Button variant="contained" color="primary" onClick={() => navigate('/checkout')} startIcon={<ShoppingCartCheckout />}>Checkout</Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                            ) : (
                                <Container style={{ backgroundColor: 'white', padding: 18 }}>
                                    <Typography variant="body1">One of the item is Sold Out, Please remove it to Proceed</Typography>
                                </Container>
                            )}

                        </div>
                    ) : (
                        <Grid container direction="column" alignItems="center" justify="center" spacing={2} style={{ marginTop: 8, backgroundColor: 'white', paddingBottom: 24 }}>
                            <Grid item>
                                <RemoveShoppingCartTwoTone style={{ fontSize: 100, color: 'gray' }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Kuch hai hi no :(</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" textAlign={'center'}>Your shopping cart is Empty.<br />Add some items and order now!</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={() => navigate('/')} startIcon={<AddShoppingCart />}>Moye Moye</Button>
                            </Grid>
                        </Grid>
                    )}
                </Container>
            )}
        </div>
    );
}

export default CartScreen;
