import React, { useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

function QuantityDropdown({ maxValue, initialValue, onChange }) {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setValue(selectedValue);
    onChange(selectedValue);
  };

  return (
    <FormControl>
      <Select
        id="quantity-select"
        value={value}
        onChange={handleChange}
      >
        {[...Array(maxValue).keys()].map((index) => (
          <MenuItem key={index + 1} value={index + 1}>Qty: {index + 1}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default QuantityDropdown;
