import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, Card, CardContent, CircularProgress, Container, Divider, Grid, Snackbar, Stack, Toolbar, Typography } from '@mui/material';
import { Add, CheckCircleOutline, LocalShipping } from '@mui/icons-material';
import Cookies from 'universal-cookie';
import { generateOrderID } from '../../components/generateOrderID';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../../components/navbar';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const RewardOrderPage = () => {
    const location = useLocation();
    const { reward } = location.state || {};

    const [address, setAddress] = useState([]);
    const [selectedAddressID, setSelectedAddressID] = useState("");
    const [selectedSize, setSelectedSize] = useState("");
    const [placed, setPlaced] = useState(false);

    const [addressLoading, setAddressLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const cookies = new Cookies();
    const userID = cookies.get('userID');
    const navigate = useNavigate();

    const theme = useTheme();

    const shirtSizes = ['M', 'L', 'XL'];

    useEffect(() => {
        getAddress()
    }, [])

    const getAddress = async () => {
        try {
            const url = process.env.REACT_APP_BASE_URL + `/address/get-user-address`;
            const response = await axios.post(url, { userID }, { withCredentials: true });
            setAddress(response.data.address);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login');
            } else if (error.request) {
                console.error('Error making request:', error.message);
                navigate('/error');
            } else {
                console.error('Error:', error.message);
            }
            return [];
        } finally {
            setAddressLoading(false)
        }
    }

    const AddressCard = styled(Card)`
        cursor: pointer;
        max-width: 300px;
        border: 1px solid ${props => props.isSelected ? props.theme.palette.primary.main : props.theme.palette.grey[500]};
        padding: 10px;
        background-color: ${props => props.isSelected ? 'white' : 'whitesmoke'};
        box-shadow: ${props => props.isSelected ? '0 0 5px rgba(0, 0, 0, 0.2)' : 'none'};
        font-weight: ${props => props.isSelected ? '700' : '500'};
    `;

    const placeRewardOrder = async () => {
        try {
            setIsLoading(true);
            await axios.post(process.env.REACT_APP_BASE_URL + `/referral/place-order`, {
                "userID": userID,
                "orderID": generateOrderID(),
                "rewardID": reward._id,
                "addressID": selectedAddressID,
                "size": selectedSize
            }, {
                withCredentials: true
            });

            setPlaced(true);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage(error.toString());
        }
    };

    const handleAddAddressClick = () => {
        navigate('/manage-address/add');
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ alignSelf: 'center' }}>
                    <LocalShipping style={{ marginRight: 6 }} /><Typography variant="h6">Order Reward</Typography>
                </Toolbar>
            </AppBar>

            {placed ? (
                <Container style={{ backgroundColor: theme.palette.primary.main }}>
                    <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
                        <img src="/success.gif" alt="Success" />
                        <Typography variant="h3" color="secondary">Shukriya!</Typography>
                        <Typography variant="body1" color="secondary" textAlign="center">
                            Your reward is on the way!<br />You will receive email updates, this may take upto 2 weeks to reach your doorsteps!
                        </Typography>
                        <Button variant="contained" color="secondary" onClick={() => navigate('/referral')}>Track Reward</Button>
                    </Stack>
                </Container>
            ) : (
                addressLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }} >
                        <CircularProgress />
                    </Box>
                ) : (
                <Container>
                    <Grid item xs={12} style={{ backgroundColor: 'white', marginTop: 20, padding: 20 }}>
                        <Typography variant="h6" color="primary" style={{ marginBottom: 8, fontSize: '16px' }}>Select Delivery Address:</Typography>
                        <div style={{ overflowX: 'auto' }}>
                            <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                {address.map((address) => (
                                    <Grid item key={address._id}>
                                        <AddressCard
                                            variant={selectedAddressID === address._id ? 'elevation' : 'outlined'}
                                            isSelected={selectedAddressID === address._id}
                                            onClick={() => {
                                                setSelectedAddressID(address._id);
                                            }}
                                        >
                                            <Typography variant="subtitle1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {address.fullName}
                                                {selectedAddressID === address._id && (
                                                    <CheckCircleOutline color="primary" />
                                                )}
                                            </Typography>
                                            <Typography variant="body2">{address.street}, {address.landmark}</Typography>
                                            <Typography variant="body2">{address.city}, {address.pinCode}</Typography>
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Typography variant="body2">Email: {address.email}</Typography>
                                            <Typography variant="body2">Phone: {address.phone}</Typography>
                                        </AddressCard>
                                    </Grid>
                                ))}
                                <Grid item>
                                    <Card
                                        variant={'outlined'}
                                        sx={{ cursor: 'pointer', maxWidth: 300 }}
                                        style={{ padding: 10 }}
                                        onClick={handleAddAddressClick}
                                    >
                                        <Button startIcon={<Add />}>Add Address</Button>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={12} style={{ backgroundColor: 'white', marginTop: 20, padding: 20 }}>
                        <Typography variant="h6" color="primary" style={{ fontSize: '16px' }}>Reward:</Typography>
                        <Grid container spacing={2} style={{ marginTop: 2 }}>

                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item md={2} xs={3} style={{ textAlign: 'center' }}>
                                                <img src={reward.images[0]} alt={reward.title} style={{ maxWidth: 80, maxHeight: 100 }} />
                                            </Grid>
                                            <Grid item md={10} xs={9} style={{ placeSelf: 'center' }}>
                                                <Typography variant="subtitle1">{reward.title}</Typography>
                                                <Typography variant='h6' style={{ marginTop: 2, marginBottom: 3 }}>
                                                    {reward.finalCoin}
                                                </Typography>
                                                {shirtSizes.map((size, index) => (
                                                    <Button key={index} style={{ marginRight: 8 }} variant={selectedSize === size ? "contained" : "outlined"} onClick={() => setSelectedSize(size)}>{size}</Button>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>

                        <Button variant="contained" style={{ marginTop: 12 }} color="primary" onClick={placeRewardOrder} disabled={!selectedAddressID || !selectedSize || isLoading}>
                            {isLoading ? <CircularProgress /> : "Order Now"}
                        </Button>
                    </Grid>

                    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} message={snackbarMessage} />
                </Container >)
            )}

        </div >
    );
};

export default RewardOrderPage;
