import React from 'react';
import { Container, Stack, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const OrderSuccessPage = () => {

    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Container style={{ backgroundColor: theme.palette.primary.main }}>
            <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
                <img src="/success.gif" alt="Success" />
                <Typography variant="h3" color="secondary">Shukriya!</Typography>
                <Typography variant="body1" color="secondary" textAlign="center">
                    Abhi maza aayega na bhidu!<br />Your order has been successfully placed.<br />You will receive updates regarding your order via email.
                </Typography>
                <Button variant="contained" color="secondary" onClick={() => navigate('/orders')}>Track Order</Button>
            </Stack>
        </Container>
    );
};

export default OrderSuccessPage;
