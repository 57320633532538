import React from 'react';
import { Typography, Icon, Container } from '@mui/material';
import { AutoMode } from '@mui/icons-material';
import styled from '@emotion/styled';

const StyledIcon = styled(Icon)`
  font-size: 80px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing(1)} !important;
`;

const StyledDescription = styled(Typography)`
  font-size: 16px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)} !important;
`;


function ComingSoonPage() {

  return (
    <Container sx={{backgroundColor: 'white', marginTop: 4, paddingBottom: 1, textAlignLast: 'center'}}>
      <StyledIcon>
        <AutoMode sx={{fontSize: 40}} />
      </StyledIcon>
      <StyledTitle variant="h6" component="h2">
        Coming Soon
      </StyledTitle>
      <StyledDescription>
        We are curating an exquisite collection.<br />
        Please return later to explore our finest selection.
      </StyledDescription>
    </Container>
  );
}

export default ComingSoonPage;
