import React, { useEffect, useState } from 'react';
import NavBar from '../../components/navbar';
import axios from 'axios';
import { Card, CardContent, CardMedia, Container, Grid, Typography, alpha, MenuItem, Menu, Box, Button, CircularProgress } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import styled from '@emotion/styled';
import ProductHorizontalScroll from '../../components/productView';
import Footer from '../../components/footer';
import { useNavigate } from 'react-router-dom';

const ScrollContainer = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #ccc transparent; /* For Firefox */
    &::-webkit-scrollbar {
        width: 10px; /* Width of the entire scrollbar */
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* Color of the tracking area */
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc; /* Color of the scroll thumb */
        border-radius: 5px; /* Radius of the scroll thumb */
        border: 2px solid transparent; /* Border around the scroll thumb */
    }
`;

export default function HomePage() {
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingKurtaData, setLoadingKurtaData] = useState(true);
    const [loadingNewLaunchData, setLoadingNewLaunchData] = useState(true);

    const [categories, setCategories] = useState([]);
    const [kurtaData, setKurtaData] = useState([]);
    const [newLaunchData, setNewLaunchData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const navigate = useNavigate();

    const fetchCategories = async () => {
        setLoadingCategories(true);
        try {
            const categoriesData = await axios.post(process.env.REACT_APP_BASE_URL + '/category/get', {
                "gender": 'Men'
            });
            setCategories(categoriesData.data['categories']);
        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setLoadingCategories(false);
        }
    };

    const fetchEidSpecial = async () => {
        setLoadingKurtaData(true);
        try {
            const kurtaData = await axios.post(process.env.REACT_APP_BASE_URL + '/product/get-products-by-catname', {
                "catName": 'Kurta'
            });
            setKurtaData(kurtaData.data['products']);
        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setLoadingKurtaData(false);
        }
    };

    const fetchNewLaunch = async () => {
        setLoadingNewLaunchData(true);
        try {
            const newLaunch = await axios.get(process.env.REACT_APP_BASE_URL + '/product/get-recent-products');
            setNewLaunchData(newLaunch.data['products']);
            console.log(newLaunch.data)
        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setLoadingNewLaunchData(false);
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchEidSpecial();
        fetchNewLaunch();
    }, []);

    const handleClick = (event, category) => {
        setAnchorEl(event.currentTarget);
        setSelectedCategory(category);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCategory = (category, subCat) => {
        navigate('/view-products', {
            state: {
                category: category,
                subCategory: subCat
            }
        })
    }

    const EidSpecialContainer = styled(Container)(({ theme }) => ({
        backgroundColor: alpha(theme.palette.primary.main, 0.25),
        marginTop: 8,
        paddingBottom: 24,
        position: 'relative',
    }));

    const ReferEarnContainer = styled(Container)(({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        marginTop: 8,
        marginBottom: 8,
        paddingBottom: 24,
        paddingTop: 18,
        position: 'relative',
        color: 'white'
    }));

    return (

        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            {loadingCategories ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Container style={{ backgroundColor: 'white', marginTop: 8 }}>
                    <ScrollContainer>
                        {categories.map(category => (
                            <Card style={{ marginTop: 10, minWidth: 120, boxShadow: 'none', alignItems: "center" }}>
                                <CardMedia
                                    component="img"
                                    height="80"
                                    width="80"
                                    image={category.catImage}
                                    alt={category.catName}
                                    onClick={() => handleCategory(category, '')}
                                    style={{ objectFit: 'contain' }}
                                />
                                <CardContent style={{ paddingBottom: 12 }}>
                                    <div style={{ display: 'flex', placeContent: 'center' }} onMouseEnter={(event) => handleClick(event, category)}>
                                        <Typography variant="body1">
                                            {category.catName}
                                        </Typography>
                                        <ArrowDropDown />
                                    </div>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl) && selectedCategory === category}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        {category.subcategories.toString().split(', ').map((subcategory) => (
                                            <MenuItem key={subcategory} onClick={() => handleCategory(category, subcategory)}>
                                                {subcategory}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </CardContent>
                            </Card>
                        ))}
                    </ScrollContainer>
                </Container>

            )}

            {loadingKurtaData ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <EidSpecialContainer>
                    <Typography variant="h6" style={{ marginTop: 8, marginBottom: 8 }}>Eid Special</Typography>
                    <ProductHorizontalScroll products={kurtaData} />
                </EidSpecialContainer>
            )};


            <ReferEarnContainer>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={1} sx={{ textAlign: { xs: 'center' } }}>
                            <img src="coin.png" alt="Coin" width={60} height={60} />
                        </Grid>
                        <Grid item xs={12} md={8} sx={{ textAlign: { xs: 'center', sm: 'center', md: 'start', lg: 'start' } }}>
                            <div>
                                <Typography variant='h5'>Refer & Earn Coins!</Typography>
                                <Typography variant='body1'>Earn +150 coins on every successful referral to a friend, and redeem them to get an Allusion T-Shirt 👕</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ textAlign: { xs: 'center', md: 'start', lg: 'end' } }}>
                            <Button variant='contained' color="secondary" onClick={() => navigate('/referral')}>Invite Now</Button>
                        </Grid>
                    </Grid>
                </Box>
            </ReferEarnContainer>

            {loadingNewLaunchData ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Container style={{ flexGrow: 1, backgroundColor: 'white', marginTop: 8 }}>
                    <Typography variant="h6" style={{ marginTop: 8, marginBottom: 8 }}>New Launch</Typography>
                    <ProductHorizontalScroll products={newLaunchData} />
                </Container>)};

            <ReferEarnContainer>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={1} sx={{ textAlign: { xs: 'center' } }}>
                            <img src="attar.png" alt="Coin" width={80} height={80} />
                        </Grid>
                        <Grid item xs={12} md={8} sx={{ textAlign: { xs: 'center', sm: 'center', md: 'start', lg: 'start' } }}>
                            <div>
                                <Typography variant='h5'>Free Attars</Typography>
                                <Typography variant='body1'>2 Free Attars with Every Order! Don't Miss Out – Claim Now and Order Today!</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ textAlign: { xs: 'center', md: 'start', lg: 'end' } }}>
                            <Button variant='contained' color="secondary" onClick={() => navigate('/cart')}>Order Now</Button>
                        </Grid>
                    </Grid>
                </Box>
            </ReferEarnContainer>

            <Footer />

        </div >

    );
}
