import React from 'react';
import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import NavBar from '../../components/navbar';
import { Refresh } from '@mui/icons-material';
import Footer from '../../components/footer';


const RefundPage = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginBottom: 12 }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <Refresh style={{ marginRight: 6 }} /><Typography variant="h6">Refund & Return</Typography>
                </Toolbar>
            </AppBar>

            <Container style={{ backgroundColor: 'white', marginTop: 8, padding: 12 }}>

                <p>Last updated: 24/10/2023</p>

                <p>If you are not entirely satisfied with your purchase, we're here to help.</p>
                <p>To be eligible for a return, your item must be:</p>
                <ul>
                    <li>In the same condition that you received it.</li>
                    <li>In the original packaging.</li>
                </ul>
                <p>Your item needs to have the receipt or proof of purchase.</p>

                <h4>Defective, Damaged, or Size Mismatched Items</h4>
                <p>If the item you received is defective, damaged, or the wrong size, please contact us within the first three
                    days of receiving your order.</p>
                <p>We will assess the issue and provide instructions for returning the item.</p>

                <h4>Refunds</h4>
                <p>Once we receive your item, we will inspect it and notify you that we have received your returned item. We
                    will immediately notify you on the status of your refund after inspecting the item.</p>
                <p>If your return is approved, we will initiate a refund to your credit card (or original method of payment).
                </p>
                <p>You will receive the credit within 3-5 working days, depending on your card issuer's policies.</p>

                <h4>Contact Us</h4>
                <p>If you have any questions on how to return your item to us, please contact us:</p>
                <ul>
                    <li>Email: contact@theallusionstore.com</li>
                </ul>

            </Container>

            <Footer />
        </div>
    );
};

export default RefundPage;
