import React, { useState, useEffect } from 'react';
import { Snackbar, CircularProgress, Button, TextField, AppBar, Typography, Container, Grid, FormControl, RadioGroup, Radio, FormControlLabel, Toolbar } from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Cookies from 'universal-cookie';
import NavBar from '../../components/navbar';
import { Person2Rounded } from '@mui/icons-material';
import styled from '@emotion/styled';

const MyProfileScreen = () => {
    const [user, setUser] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const cookies = new Cookies();
    const userID = cookies.get('userID');


    const shirtSizes = ['S', 'M', 'L', 'XL', 'XXL', 'XXXL']
    const pantSizes = ['28', '30', '32', '34', '36', '38', '40', '42']

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        try {
            const url = process.env.REACT_APP_BASE_URL + '/profile/get-profile';
            const response = await axios.post(url, { userID }, { withCredentials: true });
            const userData = response.data.user;
            setUser(userData);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleSubmit = async (values) => {
        try {
            setIsSaving(true);
            const url = process.env.REACT_APP_BASE_URL + `/profile/save`;
            await axios.post(url, {
                userID,
                fullName: values.fullName,
                email: values.email,
                gender: values.gender,
                upper: values.upper,
                lower: values.lower
            }, { withCredentials: true });
            setIsSaving(false);
            setSnackbarMessage('Profile Saved');
            setSnackbarOpen(true);
            setTimeout(() => {
                setSnackbarOpen(false);
            }, 3000);
        } catch (error) {
            console.error('Error saving profile details:', error);
            setIsSaving(false);
            setSnackbarMessage('An error occurred');
            setSnackbarOpen(true);
        }
    };

    useEffect(() => {
        formik.setValues({
            fullName: user ? user.fullName : '',
            email: user ? user.email : '',
            mobile: user ? user.mobile : '',
            gender: user ? user.gender : '',
            upper: user ? user.upper : '',
            lower: user ? user.lower : ''
        });
    }, [user]);

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            mobile: '',
            gender: '',
            upper: '',
            lower: ''
        },
        validationSchema: Yup.object({
            fullName: Yup.string().required('Full Name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            gender: Yup.string().required('Gender is required'),
            upper: Yup.string().required('Shirt size is required'),
            lower: Yup.string().required('Pant size is required')
        }),
        onSubmit: handleSubmit,
    });


    const CustomRadioLabel = styled(FormControlLabel)(({ theme, selected }) => ({
        padding: '8px 16px',
        marginRight: '20px',
        borderRadius: 8,
        border: `2px solid ${theme.palette.grey[400]}`,
        cursor: 'pointer',
        backgroundColor: selected ? theme.palette.primary.main : 'transparent',
        color: selected ? theme.palette.common.white : 'inherit',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
    }));

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <Person2Rounded style={{ marginRight: 6 }} /><Typography variant="h6">My Profile</Typography>
                </Toolbar>
            </AppBar>

            <Container p={4} style={{ backgroundColor: 'white', marginTop: 12, paddingBottom: 12 }}>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                />

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" sx={{ marginTop: 2, marginBottom: 2 }}>Personal Details</Typography>
                                    <TextField
                                        name="fullName"
                                        label="Full Name"
                                        value={formik.values.fullName}
                                        onChange={formik.handleChange}
                                        error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                                        helperText={formik.touched.fullName && formik.errors.fullName}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="email"
                                        label="Email Address"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="mobile"
                                        label="Mobile Number"
                                        value={formik.values.mobile}
                                        InputProps={{ readOnly: true }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" sx={{ marginTop: 1, marginBottom: 1 }}>Gender</Typography>
                                    <FormControl component="fieldset" error={formik.touched.gender && Boolean(formik.errors.gender)}>
                                        <RadioGroup sx={{ marginLeft: 1 }} name="gender" value={formik.values.gender} onChange={formik.handleChange} row>
                                            <CustomRadioLabel
                                                value="Male"
                                                control={<Radio style={{
                                                    display: 'none'
                                                }} />}
                                                label="Male"
                                                selected={formik.values.gender === 'Male'}
                                                onClick={() => formik.setFieldValue('gender', 'Male')}
                                            />
                                            <CustomRadioLabel
                                                value="Female"
                                                control={<Radio style={{
                                                    display: 'none'
                                                }} />}
                                                label="Female"
                                                selected={formik.values.gender === 'Female'}
                                                onClick={() => formik.setFieldValue('gender', 'Female')}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" sx={{ marginTop: 1, marginBottom: 1 }}>Shirt Size</Typography>
                                    <FormControl component="fieldset" error={formik.touched.upper && Boolean(formik.errors.upper)}>
                                        <RadioGroup name="upper" value={formik.values.upper} onChange={formik.handleChange} row>
                                            {shirtSizes.map(size => (
                                                <CustomRadioLabel key={size} value={size} control={<Radio style={{
                                                    display: 'none'
                                                }} />} label={size}
                                                    selected={formik.values.upper === size}
                                                    onClick={() => formik.setFieldValue('upper', size)}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" sx={{ marginTop: 1, marginBottom: 1 }}>Pant Size</Typography>
                                    <FormControl component="fieldset" error={formik.touched.lower && Boolean(formik.errors.lower)}>
                                        <RadioGroup name="lower" value={formik.values.lower} onChange={formik.handleChange} row>

                                            {pantSizes.map(size => (
                                                <CustomRadioLabel key={size} value={size} control={<Radio style={{
                                                    display: 'none'
                                                }} />} label={size}
                                                    selected={formik.values.lower === size}
                                                    onClick={() => formik.setFieldValue('lower', size)}
                                                />
                                            ))}
                                        </RadioGroup>

                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 3, marginBottom: 2 }} disabled={isSaving}>
                                {isSaving ? <CircularProgress size={24} /> : 'Save Profile'}
                            </Button>
                        </form>
                    </Grid>
                </Grid>

            </Container>
        </div>

    );
};

export default MyProfileScreen;

