import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Card, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

const RootContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
});

const StyledCard = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 400,
    padding: theme.spacing(2),
    textAlign: 'center',
}));

const FormContainer = styled('form')({
    width: '100%',
    marginTop: (theme) => theme.spacing(3),
});

const SubmitButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(2, 0, 1),
    padding: theme.spacing(2)
}));

const ImageContainer = styled(Container)(({ theme }) => ({
    margin: theme.spacing(1, 0, 1),
    padding: theme.spacing(1)
}));

const LoginPage = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();

    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [orderID, setOrderID] = useState('');
    const [verificationSent, setVerificationSent] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [otpError, setOtpError] = useState('');

    const [verifying, setVerifying] = useState(false);
    const [sending, setSending] = useState(false);

    const handleSendOTP = async () => {
        if (validatePhoneNumber(phoneNumber)) {
            setSending(true)
            try {
                const otpResponse = await axios.post(process.env.REACT_APP_BASE_URL + '/login/send-otp', {
                    "phoneNumber": '91' + phoneNumber
                });
                setOrderID(otpResponse.data['orderID'])
                setVerificationSent(true);
            } catch (error) {
                console.error('Error:', error);
                setPhoneNumberError(error);
            } finally {
                setSending(false)
            }
        }
    };

    const handleVerifyOTP = async () => {
        if (validateOTP(otp)) {
            setVerifying(true);
            try {
                const verifyOTPResponse = await axios.post(process.env.REACT_APP_BASE_URL + '/login/verify-otp', {
                    "phoneNumber": '91' + phoneNumber,
                    "orderId": orderID,
                    "otp": otp,
                });
                if (verifyOTPResponse.data["isOTPVerified"]) {
                    const userToken = await axios.post(process.env.REACT_APP_BASE_URL + '/login', {
                        "mobile": '91' + phoneNumber
                    }, { withCredentials: true });
                    cookies.set("userID", userToken.data['userID']);
                    navigate('/');
                } else {
                    setOtpError("Incorrect OTP")
                }
            } catch (error) {
                console.error('Error:', error);
                setPhoneNumberError(error);
            } finally {
                setVerifying(false);
            }
        }
    };

    const validatePhoneNumber = (inputPhoneNumber) => {
        if (/^[789][0-9]{9}$/.test(inputPhoneNumber)) {
            setPhoneNumberError('');
            return true;
        } else {
            setPhoneNumberError('Please Enter valid Phone number');
            return false;
        }
    };

    const validateOTP = (inputOTP) => {
        if (/^\d{6}$/.test(inputOTP)) {
            setOtpError('');
            return true;
        } else {
            setOtpError('Enter valid 6-Digit OTP');
            return false;
        }
    };

    const handlePhoneNumberChange = (e) => {
        const inputPhoneNumber = e.target.value.replace(/\D/g, '').slice(0, 10);
        setPhoneNumber(inputPhoneNumber);
    };

    const handleOTPChange = (e) => {
        const inputOTP = e.target.value.replace(/\D/g, '').slice(0, 6);
        setOtp(inputOTP);
        validateOTP(inputOTP);
    };


    return (
        <RootContainer>
            <StyledCard>
                <ImageContainer>
                    <img src="favicon.png" height={200} alt='Allusion'></img>
                </ImageContainer>
                <Typography component="h3" variant="h6" sx={{ fontSize: { xs: '1rem', lg: '1.25rem' } }} marginBottom={1}>
                    {verificationSent ? 'Enter OTP sent to +91-' + phoneNumber : 'Welcome to Allusion!'}
                </Typography>
                <FormContainer noValidate>
                    {verificationSent ? (
                        <>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="otp"
                                label="Enter OTP"
                                name="otp"
                                autoFocus
                                value={otp}
                                onChange={handleOTPChange}
                                error={!!otpError}
                                helperText={otpError}
                            />
                            {verifying ? (
                                <CircularProgress />
                            ) : (
                                <SubmitButton
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleVerifyOTP}
                                >
                                    Verify OTP
                                </SubmitButton>
                            )}
                        </>
                    ) : (
                        <>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="phoneNumber"
                                label="Phone Number"
                                name="phoneNumber"
                                autoComplete="tel"
                                autoFocus
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                error={!!phoneNumberError}
                                helperText={phoneNumberError}
                            />
                            {sending ? (
                                <CircularProgress />
                            ) : (
                                <SubmitButton
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSendOTP}
                                >
                                    Send OTP
                                </SubmitButton>
                            )}
                        </>
                    )}
                </FormContainer>
            </StyledCard >
        </RootContainer >
    );
};

export default LoginPage;
