import React, { useState, useEffect } from 'react';
import { Typography, Grid, Select, MenuItem, Box, CircularProgress, AppBar, Toolbar, Container } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ComingSoonPage from '../../components/coming_soon';
import axios from 'axios';
import NavBar from '../../components/navbar';
import { BurstMode } from '@mui/icons-material';
import ProductCard from '../../components/product_card';

function ShowProductsPage() {
    const location = useLocation();
    const { category, subCategory } = location.state || {};

    const [products, setProducts] = useState([]);
    const [sortByPrice, setSortByPrice] = useState('Sort');
    const [subCat, setSubCat] = useState(subCategory);

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        loadProducts();
    }, [sortByPrice, subCat]);

    const loadProducts = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + `/product/get-by-catID`, { catID: category._id, sortPriceAscending: sortByPrice, subCategory: subCat });
            setProducts(response.data.products);
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('An error occurred while fetching products', error);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <BurstMode style={{ marginRight: 6 }} /><Typography variant="h6">{category.catName}</Typography>
                </Toolbar>
            </AppBar>

            <Container sx={{ marginTop: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <div style={{ backgroundColor: 'white', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
                            <Select
                                value={subCat || 'All'}
                                onChange={(e) => setSubCat(e.target.value)}
                                fullWidth
                            >
                                <MenuItem value="All">All</MenuItem>
                                {category.subcategories.split(', ').map(subCategory => (
                                    <MenuItem key={subCategory} value={subCategory}>{subCategory}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <div style={{ backgroundColor: 'white', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
                            <Select
                                value={sortByPrice}
                                onChange={(e) => setSortByPrice(e.target.value)}
                                fullWidth
                            >
                                <MenuItem value="Sort">Sort by</MenuItem>
                                <MenuItem value="Low to High">Low to High</MenuItem>
                                <MenuItem value="High to Low">High to Low</MenuItem>
                            </Select>
                        </div>
                    </Grid>
                </Grid>
            </Container>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Container>
                    {products.length > 0 ? (
                        <Grid container spacing={2} style={{ padding: '10px' }}>
                            {products.map(product => (
                                <Grid key={product.id} item xs={6} sm={6} md={4} lg={3}>
                                    <ProductCard product={product} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <ComingSoonPage />
                    )}
                </Container>
            )}
        </div>
    );
}



export default ShowProductsPage;
