import React, { useEffect, useState } from 'react';
import { Card, CardContent, Divider, Button, TextField, Typography, Grid, IconButton, AppBar, Toolbar, Container, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
import { Cancel, CheckCircle, Receipt, Refresh, Star, Warning } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBar from '../../components/navbar';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useTheme } from '@emotion/react';

const RatingStars = ({ rating, onStarClick }) => {
    return (
        <Grid container justifyContent="center">
            {[...Array(5)].map((_, index) => (
                <IconButton key={index} onClick={() => onStarClick(index + 1)}>
                    <Star sx={{ fontSize: 28 }} color={index < rating ? "primary" : "grey"} />
                </IconButton>
            ))}
        </Grid>
    );
};

const DeliveryDetails = ({ order }) => {
    return (
        <Card elevation={8} style={{ marginTop: 12, marginBottom: 12 }}>
            <CardContent>
                <Typography variant="subtitle1">Delivery Details</Typography>
                <Divider style={{ margin: '8px 0' }} />
                <Typography>{order.address.fullName}</Typography>
                <Typography>{`${order.address.street}, ${order.address.landmark}`}</Typography>
                <Typography>{`${order.address.city}, ${order.address.pinCode}`}</Typography>
                <Typography>{`Email: ${order.address.email}`}</Typography>
                <Typography>{`Mobile: ${order.address.phone}`}</Typography>
                <Divider style={{ margin: '8px 0' }} />
                <Typography>Delivery Date: {order.deliveryDate}</Typography>
            </CardContent>
        </Card>
    );
};

const PriceDetails = ({ order }) => {
    return (
        <Card elevation={8} style={{ marginTop: 12 }}>
            <CardContent>
                <Typography variant="subtitle1">Order Pricing</Typography>
                <Divider style={{ margin: '8px 0' }} />
                <Grid container spacing={2} direction="column">
                    <Grid item container justifyContent="space-between">
                        <Typography>Actual Price:</Typography>
                        <Typography>₹{order.actualUnitPrice}.00</Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <Typography>Discount:</Typography>
                        <Typography>- ₹{order.unitDiscount * order.quantity}.00</Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <Typography>Delivery Fee:</Typography>
                        <Typography sx={{ color: 'green' }}>FREE (₹50.00)</Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <Typography>Total Amount:</Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: 18 }}>₹{order.sellingPrice}.00</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ margin: '8px 0' }} />
                <Typography>{order.payment === 'COD' ? 'Cash on Delivery (COD)' : 'Paid via RazorPay'}</Typography>
            </CardContent>
        </Card>
    );
};

const ProductDetails = ({ order }) => {
    return (
        <Card elevation={8} style={{ marginTop: 12 }}>
            <CardContent>
                <Typography variant="subtitle1">Product Details</Typography>
                <Divider style={{ margin: '8px 0' }} />
                <Grid container spacing={2}>
                    <Grid item md={2} xs={3} style={{ textAlign: 'center' }}>
                        <img src={order.productID.images[0]} alt={order.productID.title} style={{ maxWidth: 80, maxHeight: 100 }} />
                    </Grid>
                    <Grid item md={10} xs={9} style={{ placeSelf: 'center' }}>
                        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>{order.productID.title}</Typography>
                        <Typography variant="body1" style={{ marginBottom: 6 }}>Size: {order.size} {order.color && `| Color: ${order.color}`} {order.quantity && `| Qty: ${order.quantity}`}</Typography>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                            <Typography variant='h6'>
                                ₹{order.productID.finalPrice * order.quantity}
                            </Typography>

                            <Typography variant="body2" style={{ marginLeft: '6px', textDecoration: 'line-through', color: 'grey', fontSize: '16px' }}>
                                ₹{order.productID.actualPrice * order.quantity}
                            </Typography>

                            <div style={{ marginLeft: '6px', backgroundColor: 'green', padding: '4px 6px', borderRadius: '4px', display: 'inline-block' }}>
                                <Typography variant="body1" style={{ color: 'white', fontSize: '12px' }}>
                                    {order.productID.discount}% off
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Divider style={{ margin: '8px 0' }} />
                <Typography>Status: <strong>{order.status}</strong></Typography>
            </CardContent>
        </Card>
    );
};

const ReviewCard = ({ userID, order, updateReviewStatus, navigate, handleSnackbar }) => {
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [rating, setRating] = useState(0);

    const handleStarClick = (star) => {
        setRating(star);
    };

    const handleSubmit = async () => {

        if (rating > 0 && title.trim() !== '' && message.trim() !== '') {

            try {
                await axios.post(process.env.REACT_APP_BASE_URL + `/review/add-review`, {
                    userID: userID,
                    productID: order.productID,
                    rating: rating.toString(),
                    title: title,
                    message: message
                }, { withCredentials: true });
                updateReviewStatus(true);
            } catch (error) {
                if (error.response.status === 401) {
                    navigate('/login')
                } else {
                    console.error('An error occurred while fetching cart items:', error);
                }
            }
        } else {
            handleSnackbar(true, 'Please fill in all fields and select a rating');
        }
    };

    return (
        <Card elevation={8} sx={{ marginBottom: 2 }}>
            <CardContent>
                <Typography variant="subtitle1">Submit Review</Typography>
                <Divider style={{ margin: '8px 0' }} />
                <RatingStars rating={rating} onStarClick={handleStarClick} />
                <TextField
                    label="Review Title"
                    variant="outlined"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="Review Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    margin="normal"
                />
                <Container sx={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" sx={{ marginTop: 1 }} onClick={handleSubmit}>Submit Review</Button>
                </Container>

            </CardContent>
        </Card>
    );
};

const ReviewSubmitted = ({ onViewReview }) => {
    return (
        <Card elevation={8} style={{ marginTop: 12, marginBottom: 12 }}>
            <CardContent>
                <Typography variant="subtitle1">Product Review</Typography>
                <Divider style={{ margin: '8px 0' }} />
                <Typography>You have already submitted a review, Thank You <CheckCircle sx={{ fontSize: 18, verticalAlign: 'text-bottom' }} /> </Typography>
                <Button variant="contained" color="primary" onClick={onViewReview} style={{ marginTop: 8 }}>View Review</Button>
            </CardContent>
        </Card>
    );
};

const OrderDetailPage = () => {
    const [hasSubmittedReview, setHasSubmittedReview] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [openReturn, setOpenReturn] = useState(false);
    const [cancellingOrder, setCancellingOrder] = useState(false);
    const [returningOrder, setReturningOrder] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const location = useLocation();
    const { order } = location.state || {};

    const cookies = new Cookies();
    const userID = cookies.get('userID');

    const theme = useTheme();

    const navigate = useNavigate();

    const handleReviewStatusUpdate = (hasSubmitted) => {
        setHasSubmittedReview(hasSubmitted);
    };

    const handleSnackbar = (open, message) => {
        setSnackbarOpen(open)
        setSnackbarMessage(message)
    }

    const onViewReview = () => {
        navigate('/manage-reviews');
    };

    useEffect(() => {
        checkIfAlreadySubmitReview();
    }, []);

    const checkIfAlreadySubmitReview = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + '/review/check', {
                userID: userID,
                productID: order.productID
            }, {
                withCredentials: true
            })
            setHasSubmittedReview(response.data.status)
        } catch (error) {

        }
    }

    const handleCancelOrder = async () => {
        setCancellingOrder(true)
        try {
            await axios.post(process.env.REACT_APP_BASE_URL + '/orders/cancel', {
                orderID: order._id,
            }, { withCredentials: true });
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('An error occurred while fetching cart items:', error);
            }
        } finally {
            setCancellingOrder(false)
            setOpenCancel(false)
            navigate('/orders')
        }
    }

    const handleReturnOrder = async () => {
        setReturningOrder(true)
        try {
            await axios.post(process.env.REACT_APP_BASE_URL + '/orders/return', {
                orderID: order._id,
            }, { withCredentials: true });
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('An error occurred while fetching cart items:', error);
            }
        } finally {
            setReturningOrder(false)
            setOpenReturn(false)
            navigate('/orders')
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <Receipt style={{ marginRight: 6 }} /><Typography variant="h6">{order.orderID}</Typography>
                </Toolbar>
            </AppBar>

            <Container>
                {/* Components for different sections */}
                <ProductDetails order={order} />
                <PriceDetails order={order} />
                <DeliveryDetails order={order} />

                {(order.status === "Ordered" || order.status === "Accepted") && (
                    <Container sx={{ textAlign: 'center', marginTop: 2, marginBottom: 2 }}>
                        <Button

                            variant='contained'
                            color='error'
                            startIcon={<Cancel />}
                            onClick={() => setOpenCancel(true)}
                        >
                            Cancel Order
                        </Button>
                    </Container>
                )}

                {(order.status === "Delivered" &&
                    (new Date() - new Date(order.deliveryDate)) / (1000 * 60 * 60 * 24) <= 3) && (

                        <Container sx={{ textAlign: 'center', marginTop: 2, marginBottom: 2 }}>
                            <Button

                                variant='contained'
                                color='primary'
                                startIcon={<Refresh />}
                                onClick={() => setOpenReturn(true)}
                            >
                                Return Order
                            </Button>
                        </Container>
                    )}

                {(!hasSubmittedReview && order.status === "Delivered" &&
                    (new Date() - new Date(order.deliveryDate)) / (1000 * 60 * 60 * 24) > 3) && (

                        <ReviewCard order={order} userID={userID} updateReviewStatus={handleReviewStatusUpdate} handleSnackbar={handleSnackbar} navigate={navigate} />
                    )}

                {(hasSubmittedReview && order.status === "Delivered") && (
                    <ReviewSubmitted onViewReview={onViewReview} />
                )}

                {(order.status === "Return") && (
                    <Typography sx={{ textAlign: 'center', marginBottom: 2 }}>We have received your request to return this order.<br />Please, package the product securely and once we receive the product and verify its condition, we will process your refund.</Typography>
                )}

                {(order.status === "Rejected" && order.payment !== "COD") && (
                    <Typography sx={{ textAlign: 'center', marginBottom: 2 }}>This Order was Rejected, You will receive your refund in 5-7 working days.</Typography>
                )}

                {(order.status === "Cancelled" && order.payment !== "COD") && (
                    <Typography sx={{ textAlign: 'center', marginBottom: 2 }}>This Order was Cancelled, You will receive your refund in 5-7 working days.</Typography>
                )}

                {(order.status === "Refund" && order.payment !== "COD") && (
                    <Typography sx={{ textAlign: 'center', marginBottom: 2 }}>Your refund has been successfully processed.<br />The amount will be credited back to the original payment method. This may take 5-7 working days for the refund to reflect in your account.</Typography>
                )}
            </Container>

            <Dialog open={openCancel} sx={{ textAlign: 'center' }}>
                <DialogTitle style={{ backgroundColor: theme.palette.primary.main, color: '#fff' }}>Kyun? Kya huyi..?</DialogTitle>
                <DialogContent style={{ backgroundColor: '#f7f7f7', marginTop: 8 }}>
                    <Typography>Are you sure?</Typography>
                    <Typography>You want to cancel this order?</Typography>
                </DialogContent>
                <DialogActions sx={{ alignSelf: 'center' }}>
                    <Button
                        onClick={handleCancelOrder}
                        disabled={cancellingOrder}
                        variant='outlined'
                        color='error'
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => setOpenCancel(false)}
                        color="primary"
                        disabled={cancellingOrder}
                        variant='contained'
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openReturn} sx={{ textAlign: 'center' }}>
                <DialogTitle style={{ backgroundColor: theme.palette.primary.main, color: '#fff' }}>Kyun? Kya huyi..?</DialogTitle>
                <DialogContent style={{ backgroundColor: '#f7f7f7', marginTop: 8 }}>
                    <Typography>Are you sure?</Typography>
                    <Typography>You want to return this order?</Typography>
                </DialogContent>
                <DialogActions sx={{ alignSelf: 'center' }}>
                    <Button
                        onClick={handleReturnOrder}
                        disabled={returningOrder}
                        variant='outlined'
                        color='error'
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => setOpenReturn(false)}
                        color="primary"
                        disabled={returningOrder}
                        variant='contained'
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => setSnackbarOpen(false)}
                    severity="primary"
                    icon={<Warning />}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default OrderDetailPage;
