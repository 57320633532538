import React, { useState, useEffect } from 'react';
import { AppBar, Tab, Tabs, TextField, Button, CircularProgress, Card, Grid, Typography, Snackbar, List, ListItem, ListItemText, Toolbar, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import NavBar from '../../components/navbar';
import { useTheme } from '@emotion/react';

const ReferralPage = () => {
    const [value, setValue] = useState(0);
    const [coins, setCoins] = useState('--');
    const [referrals, setReferrals] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [sendingInvite, setSendingInvite] = useState(false);
    const [phone, setPhone] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [referralLoading, setReferralLoading] = useState(true);
    const [rewardLoading, setRewardLoading] = useState(true);

    const cookies = new Cookies();
    const userID = cookies.get('userID');
    const theme = useTheme();

    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getReferrals = async () => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_BASE_URL + '/referral/get-referrals',
                { userID },
                { withCredentials: true }
            );
            return response.data;
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('Error fetching referrals:', error);
            }
        } finally {
            setReferralLoading(false);
        }
    };

    const getRewards = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `/referral/get-rewards`, {
                withCredentials: true
            });
            return response.data.rewards;
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('Error fetching rewards:', error);
            }
        } finally {
            setRewardLoading(false);
        }
    };

    const addReferral = async (phone) => {
        try {
            const url = process.env.REACT_APP_BASE_URL + `/referral/new-referral`;
            const data = { userID, invited: phone };

            const response = await axios.post(url, data, {
                withCredentials: true
            });

            if (response.status === 201) {
                setSnackbarMessage('User already exists, Try another!')
                setSnackbarOpen(true)
            } else if (response.status === 202) {
                setSnackbarMessage('User already invited!')
                setSnackbarOpen(true)
            } else if (response.status === 200) {
                setSnackbarMessage('User Invited :)')
                setSnackbarOpen(true)
            }
        } catch (error) {
            console.error('Error adding referral:', error);
        } finally {
            setSendingInvite(false);
        }
    };

    const fetchData = async () => {
        try {
            const referralsResult = await getReferrals();
            const rewardsResult = await getRewards();
            setCoins(referralsResult.coins.toString());
            setReferrals(referralsResult.history);
            setRewards(rewardsResult);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSendInvite = async () => {
        try {
            const phoneRegex = /^[789][0-9]{9}$/;
            if (!phoneRegex.test(phone)) {
                setSnackbarMessage('Enter Valid Phone Number')
                setSnackbarOpen(true);
            } else {
                setSendingInvite(true);
                await addReferral(phone);
                setPhone('');
                fetchData();
            }

        } catch (error) {
            console.error('Error sending invite:', error);
            setSendingInvite(false);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ alignSelf: 'center' }}>
                    <Typography variant="h6">Refer & Earn Coins</Typography>
                </Toolbar>
                <Tabs value={value} onChange={handleChange} style={{ alignSelf: 'center' }} TabIndicatorProps={{ style: { background: 'white' } }}>
                    <Tab style={{ color: value === 0 ? 'white' : theme.palette.lightColor.main }} label="Earn Coins" />
                    <Tab style={{ color: value === 1 ? 'white' : theme.palette.lightColor.main }} label="Use Coins" />
                </Tabs>
            </AppBar>

            {value === 0 && (

                referralLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }} >
                        <CircularProgress />
                    </Box>
                ) : (
                    <Container style={{ marginTop: 12, textAlign: 'center' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card style={{ paddingBottom: 12 }}>
                                    <Box
                                        padding={2}
                                        bgcolor="primary.main"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid container style={{ placeContent: 'center' }} alignItems="center">
                                            <Grid item>
                                                <Box width={32} height={32}>
                                                    <img src="/coin.png" alt="Coin" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3" sx={{ color: 'white', marginLeft: 1 }}>
                                                    {coins}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Typography style={{ marginTop: 8, marginBottom: 8 }} gutterBottom>
                                        Earn +150 coins on each successful referral
                                    </Typography>
                                    <TextField
                                        label="Friend's Mobile Number"
                                        variant="outlined"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={sendingInvite}
                                        onClick={handleSendInvite}
                                        style={{ marginLeft: 12, verticalAlign: 'bottom' }}
                                    >
                                        {sendingInvite ? <CircularProgress size={24} /> : 'Send Invite'}
                                    </Button>
                                </Card>
                            </Grid>
                            {/* Coins History */}
                            <Grid item xs={12}>
                                <Card>
                                    <Typography style={{ marginTop: 12 }} variant="h6" gutterBottom>
                                        Coins History
                                    </Typography>
                                    <List style={{ marginLeft: 12, marginRight: 12 }}>
                                        <ListItem sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            boxShadow: 4,
                                            borderRadius: 2,
                                        }} key={-1}>
                                            <ListItemText>
                                                <Typography variant="body1" color="primary">Welcome Bonus</Typography>
                                            </ListItemText>
                                            <ListItemText style={{ textAlign: 'end' }}>
                                                <Typography variant="body1" style={{ color: 'green', fontSize: 16 }}>+200</Typography>
                                            </ListItemText>
                                        </ListItem>
                                        {referrals.map((referral, index) => (
                                            <ListItem key={index} sx={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                boxShadow: 4,
                                                borderRadius: 2,
                                                marginTop: 2
                                            }}>
                                                <>
                                                    <ListItemText>
                                                        <Typography variant="body1">{referral.rewardID ? referral.rewardID.title : referral.invited.toString()}</Typography>
                                                        <Typography variant="body1">Status: {referral.rewardID ? referral.status : referral.status}</Typography>
                                                    </ListItemText>
                                                    <ListItemText style={{ textAlign: 'end' }}>
                                                        <Typography variant="body1" style={{ color: referral.orderID ? 'red' : 'green', fontSize: 16 }}>
                                                            {referral.rewardID ? `-${referral.rewardID.finalCoin}` : `+${referral.coins}`}
                                                        </Typography>
                                                    </ListItemText>
                                                </>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>)
            )}

            {
                value === 1 && (
                    rewardLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }} >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Container style={{ marginTop: 12, textAlign: 'center' }}>
                            <Grid container spacing={2}>
                                {rewards.map((reward, index) => (
                                    <Grid item key={index} xs={6} sm={4} md={3}>
                                        <RewardCard reward={reward} coins={coins} fetchData={fetchData} setSnackbarMessage={setSnackbarMessage}
                                            setSnackbarOpen={setSnackbarOpen}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                    )
                )
            }

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                ContentProps={{
                    sx: {
                        backgroundColor: theme.palette.primary.main,
                    },
                }}
            />
        </div >
    );
};

const RewardCard = ({ reward, coins, setSnackbarMessage, setSnackbarOpen }) => {
    const navigate = useNavigate();

    const handleUseReward = () => {
        if (coins !== '--' && parseInt(coins) >= reward.finalCoin) {
            navigate('/reward-order', { state: { reward } });
        } else {
            setSnackbarMessage('Insufficient Coins,\nEarn more coins by inviting friends & family!');
            setSnackbarOpen(true);
        }
    };

    return (
        <Card>
            <img src={reward.images[0]} style={{ width: '100%', height: 'auto' }} alt={reward.title} />
            <Typography>{reward.title}</Typography>
            <Button variant="outlined" color="primary" style={{ marginBottom: 10 }} onClick={handleUseReward}>
                Use {reward.finalCoin}
            </Button>
        </Card>
    );
};

export default ReferralPage;
