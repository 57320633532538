import React from 'react';
import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import NavBar from '../../components/navbar';
import { PrivacyTip } from '@mui/icons-material';
import Footer from '../../components/footer';


const PrivacyPolicyPage = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginBottom: 12 }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <PrivacyTip style={{ marginRight: 6 }} /><Typography variant="h6">Privacy Policy</Typography>
                </Toolbar>
            </AppBar>

            <Container style={{ backgroundColor: 'white', marginTop: 8, padding: 12 }}>

                <p>Last updated: 24/10/2023</p>
                <p>
                    "The Allusion Store" is committed to protecting and respecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit our app or use our services.
                </p>

                <Typography variant="h6">Information We Collect</Typography>
                <p>
                    We may collect and process the following information:
                </p>
                <ol>
                    <li>
                        <strong>Personal Information:</strong> When you create an account or place an order, we collect your name, email address, phone number, and delivery address. We use this information to fulfill your orders, provide customer support, and keep you updated on your order status.
                    </li>
                    <li>
                        <strong>Payment Information:</strong> If you make a purchase on our app, your payment is processed by Razorpay, our payment gateway. We do not store your payment information, such as credit card numbers. Please review Razorpay's Privacy Policy for details on how they handle payment information.
                    </li>
                    <li>
                        <strong>App Usage Data:</strong> We may collect non-personal information about your visits to our app, including the pages you view and how you navigate our site. This information helps us improve our app and provide a better user experience.
                    </li>
                </ol>

                <Typography variant="h6">How We Use Your Information</Typography>
                <p>
                    We use your personal information for the following purposes:
                </p>
                <ol>
                    <li>
                        <strong>Order Fulfillment:</strong> To process and deliver your orders, we share your name, address, and contact information with our delivery partners.
                    </li>
                    <li>
                        <strong>Customer Support:</strong> To assist you with inquiries, returns, and other customer service requests.
                    </li>
                    <li>
                        <strong>Communication:</strong> To keep you updated on your orders, promotions, and important information related to our services.
                    </li>
                </ol>

                <Typography variant="h6">Sharing of Information</Typography>
                <p>
                    We do not sell or rent your personal information to third parties. However, we may share your information with:
                </p>
                <ul>
                    <li>Delivery partners for order fulfillment.</li>
                    <li>Razorpay for payment processing.</li>
                    <li>Law enforcement authorities, if required by law.</li>
                </ul>

                <Typography variant="h6">Data Security</Typography>
                <p>
                    We take appropriate measures to protect your personal data. While we strive to use commercially acceptable means to protect your information, we cannot guarantee absolute security.
                </p>

                <Typography variant="h6">Your Rights</Typography>
                <p>
                    You have the right to access, correct, or delete your personal information. If you have questions about your personal data or would like to exercise your rights, please contact us at <a href="mailto:contact@theallusionstore.com">contact@theallusionstore.com</a>
                </p>

                <Typography variant="h6">Changes to This Policy</Typography>
                <p>
                    We may update this Privacy Policy from time to time to reflect changes in our practices and services. We encourage you to review this policy periodically.
                </p>

                <Typography variant="h6">Contact Us</Typography>
                <p>
                    If you have any questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:contact@theallusionstore.com">contact@theallusionstore.com</a>
                </p>
            </Container>

            <Footer />
        </div>
    );
};

export default PrivacyPolicyPage;
