import React from 'react';
import { Container, Typography, Grid, Link } from '@mui/material';
import { Instagram, Facebook } from '@mui/icons-material';
import styled from '@emotion/styled';

const FooterContainer = styled('footer')(({ theme }) => ({
    marginTop: 24,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    paddingTop: 12,
}));

const Footer = () => {
    return (
        <FooterContainer>
            <Container style={{paddingBottom: 12}}>
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={5} sx={{ textAlign: { xs: 'center', lg: 'start' } }}>
                        <Link href="/">
                            <img src="line-logo-large.png" width={200} alt="Allusion" />
                        </Link>
                        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
                            We're passionate about fashion. Our goal is to provide you with the latest trends, high-quality clothing, and exceptional shopping experiences. Whether you're searching for the perfect outfit, accessories, or footwear, we've got you covered.
                        </Typography>
                        <div className="social-links">
                            <Link href="https://www.instagram.com/theallusionstore/" target="_blank" style={{color: 'white'}}>
                                <Instagram />
                            </Link>
                            <Link href="https://www.facebook.com/profile.php?id=61555799950976" target="_blank" style={{color: 'white'}}>
                                <Facebook />
                            </Link>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Typography variant="h6">Useful Links</Typography>
                        <ul>
                            <li style={{ marginBottom: '8px' }}><Link href="/contact-us" color="inherit"><Typography variant="body2">Contact Us</Typography></Link></li>
                            <li style={{ marginBottom: '8px' }}><Link href="/privacy-policy" color="inherit"><Typography variant="body2">Privacy Policy</Typography></Link></li>
                            <li style={{ marginBottom: '8px' }}><Link href="/return-and-refund" color="inherit"><Typography variant="body2">Return and Refund Policy</Typography></Link></li>
                            <li style={{ marginBottom: '8px' }}><Link href="/terms-and-condition" color="inherit"><Typography variant="body2">Terms & Condition</Typography></Link></li>
                        </ul>
                    </Grid>
                </Grid>
            </Container>
            <div style={{ backgroundColor: '#111', padding: '12px 0' }}>
                <Container>
                    <Typography variant="body2" align="center">&copy; Copyright <strong>The Allusion Store</strong>. All Rights Reserved</Typography>
                    <Typography variant="body2" align="center">Designed by <Link href="https://entwicklera.com/" target="_blank" color="inherit">Entwicklera</Link></Typography>
                </Container>
            </div>
        </FooterContainer>
    );
};

export default Footer;
