import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { AppBar, Container, Toolbar, InputBase, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Login from '@mui/icons-material/Login';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { ArrowDropDown, Favorite, LocalShipping, Logout, MyLocation, Reviews } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';

const WebLayout = styled('div')({
    display: 'flex',
    justifyContent: "center",
    '@media (max-width: 600px)': {
        display: 'none',
    },
});

const MobileLayout = styled('div')({
    display: 'none',
    '@media (max-width: 600px)': {
        display: 'flex',
        flexDirection: 'column',
    },
});

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("#FFFFFF", 0.25),
    '&:hover': {
        backgroundColor: alpha("#FFFFFF", 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('xl')]: {
            width: '80ch',
        },
        [theme.breakpoints.down('xl')]: {
            width: '60ch',
        },
        [theme.breakpoints.down('lg')]: {
            width: '40ch',
        },
    },
}));

export default function NavBar() {
    const cookies = new Cookies();
    const isLogin = cookies.get("userID") ? true : false;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await axios.get(process.env.REACT_APP_BASE_URL + '/login/logout', { withCredentials: true })
            cookies.remove('userID')
            navigate('/login');
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <AppBar position='static'>
            <Container maxWidth="xl">

                <WebLayout>
                    <Toolbar>
                        <img src="/line-logo-large.png" height={70} alt='Allusion' style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
                        <Search onClick={() => navigate('/search')}>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search for products"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                        {isLogin ? (
                            <div>
                                <IconButton color="inherit" onClick={() => navigate('/cart')}>
                                    <ShoppingCart />
                                    <Typography variant="body1" style={{ marginLeft: '4px' }}>Cart</Typography>
                                </IconButton>
                                <IconButton color="inherit" onClick={() => navigate('/orders')}>
                                    <LocalShipping />
                                    <Typography variant="body1" style={{ marginLeft: '4px' }}>Orders</Typography>
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    aria-controls="web-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <AccountCircle />
                                    <Typography variant="body1" style={{ marginLeft: '4px' }}>Account</Typography>
                                    <ArrowDropDown />
                                </IconButton>
                                <Menu
                                    id="web-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    MenuProps={{ disableScrollLock: true }}

                                >
                                    <MenuItem onClick={() => {navigate('/my-profile')}}>
                                        <IconButton color="inherit">
                                            <AccountCircle />
                                            <Typography variant="body1" style={{ marginLeft: '4px' }}>Profile</Typography>
                                        </IconButton>
                                    </MenuItem>
                                    <MenuItem onClick={() => {navigate('/manage-address')}}>
                                        <IconButton color="inherit">
                                            <MyLocation />
                                            <Typography variant="body1" style={{ marginLeft: '4px' }}>Address</Typography>
                                        </IconButton>
                                    </MenuItem>
                                    <MenuItem onClick={() => {navigate('/wishlist')}}>
                                        <IconButton color="inherit">
                                            <Favorite />
                                            <Typography variant="body1" style={{ marginLeft: '4px' }}>Wishlist</Typography>
                                        </IconButton>
                                    </MenuItem>
                                    <MenuItem onClick={() => {navigate('/manage-reviews')}}>
                                        <IconButton color="inherit">
                                            <Reviews />
                                            <Typography variant="body1" style={{ marginLeft: '4px' }}>Reviews</Typography>
                                        </IconButton>
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout}>
                                        <IconButton color="inherit">
                                            <Logout />
                                            <Typography variant="body1" style={{ marginLeft: '4px' }}>Logout</Typography>
                                        </IconButton>
                                    </MenuItem>
                                </Menu>
                            </div>
                        ) : (
                            <div>
                                <Button variant="contained" color="secondary" startIcon={<Login />} onClick={() => navigate('/login')}>Login</Button>
                            </div>
                        )}
                    </Toolbar>
                </WebLayout>

                <MobileLayout>
                    <Toolbar style={{ justifyContent: "center" }}>
                        <img src="line-logo-large.png" height={70} alt='Allusion' onClick={() => navigate('/')} />
                    </Toolbar>
                    <Toolbar>
                        <Search onClick={() => navigate('/search')}>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search for products"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                    </Toolbar>
                    {isLogin ? (
                        <Toolbar style={{ justifyContent: "space-between" }}>
                            <IconButton color="inherit" onClick={() => navigate('/cart')}>
                                <ShoppingCart />
                                <Typography variant="body1" style={{ marginLeft: '4px' }}>Cart</Typography>
                            </IconButton>
                            <IconButton color="inherit" onClick={() => navigate('/orders')}>
                                <LocalShipping />
                                <Typography variant="body1" style={{ marginLeft: '4px' }}>Orders</Typography>
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-controls="menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <AccountCircle />
                                <Typography variant="body1" style={{ marginLeft: '4px' }}>Account</Typography>
                            </IconButton>

                        </Toolbar>
                    ) : (
                        <Toolbar style={{ justifyContent: "center", marginBottom: 8 }}>
                            <Button variant="contained" color="secondary" onClick={() => navigate('/login')} startIcon={<Login />}>Login</Button>
                        </Toolbar>
                    )}


                </MobileLayout>
            </Container>
        </AppBar>
    );
}