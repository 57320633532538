import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/home/home';
import LoginPage from './pages/login/login';
import ProductPage from './pages/product/product';
import CartScreen from './pages/cart/cart';
import CheckoutScreen from './pages/checkout/checkout';
import AddressScreen from './pages/address/manage';
import AddNewAddressScreen from './pages/address/add';
import EditAddressScreen from './pages/address/edit';
import OrderSuccessPage from './pages/checkout/success';
import OrderScreen from './pages/orders/manage';
import OrderDetailPage from './pages/orders/details';
import ReviewScreen from './pages/review/manage';
import EditReview from './pages/review/edit';
import ShowProductsPage from './pages/category/products';
import WishlistScreen from './pages/wishlist/wishlist';
import MyProfileScreen from './pages/profile.dart/profile';
import ReferralPage from './pages/referral/referral';
import RewardOrderPage from './pages/referral/reward_order';
import PrivacyPolicyPage from './pages/legal/privacy';
import TermsPage from './pages/legal/terms';
import RefundPage from './pages/legal/refund';
import SearchPage from './pages/search/search';
import ContactUsPage from './pages/legal/contact';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0f2649"
    },
    secondary: {
      main: '#FFFFFF'
    },
    lightColor: {
      main: '#757575'
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/cart" element={<CartScreen />} />
          <Route path="/wishlist" element={<WishlistScreen />} />
          <Route path="/checkout" element={<CheckoutScreen />} />
          <Route path="/manage-address" element={<AddressScreen />} />
          <Route path="/manage-address/add" element={<AddNewAddressScreen />} />
          <Route path="/manage-address/edit" element={<EditAddressScreen />} />
          <Route path="/checkout/success" element={<OrderSuccessPage />} />
          <Route path="/orders" element={<OrderScreen />} />
          <Route path="/orders/detail" element={<OrderDetailPage />} />
          <Route path="/manage-reviews" element={<ReviewScreen />} />
          <Route path="/manage-reviews/edit" element={<EditReview />} />
          <Route path="/view-products" element={<ShowProductsPage />} />
          <Route path="/my-profile" element={<MyProfileScreen />} />
          <Route path="/referral" element={<ReferralPage />} />
          <Route path="/reward-order" element={<RewardOrderPage />} />
          <Route path="/search" element={<SearchPage />} />

          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/return-and-refund" element={<RefundPage />} />
          <Route path="/terms-and-condition" element={<TermsPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
