import React, { useState, useEffect } from 'react';
import { Alert, AppBar, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, Divider, Grid, Snackbar, Toolbar, Typography } from '@mui/material';
import { Add, AddShoppingCart, CheckCircleOutline, RemoveShoppingCartTwoTone, ShoppingCartCheckout, Warning } from '@mui/icons-material';
import NavBar from '../../components/navbar';
import styled from '@emotion/styled';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { generateOrderID } from '../../components/generateOrderID';

const AddressCard = styled(Card)`
  cursor: pointer;
  max-width: 300px;
  border: 1px solid ${props => props.isSelected ? props.theme.palette.primary.main : props.theme.palette.grey[500]};
  padding: 10px;
  background-color: ${props => props.isSelected ? 'white' : 'whitesmoke'};
  box-shadow: ${props => props.isSelected ? '0 0 5px rgba(0, 0, 0, 0.2)' : 'none'};
  font-weight: ${props => props.isSelected ? '700' : '500'};
`;

const CheckoutScreen = () => {
    const [selectedAddressID, setSelectedAddressID] = useState('');
    const [address, setAddress] = useState([]);
    const [selectedName, setSelectedName] = useState('');
    const [selectedPhone, setSelectedPhone] = useState('');
    const [selectedEmail, setSelectedEmail] = useState('');

    const [isPlacingOrder, setIsPlacingOrder] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emptyCart, setEmptyCart] = useState(false);

    const [cartItems, setCartItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState('');
    const [discounted, setDiscounted] = useState('');

    const navigate = useNavigate();

    const cookies = new Cookies();
    const userID = cookies.get('userID');

    useEffect(() => {
        getCartItems();
        getAddress();
    }, []);

    const getCartItems = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + `/cart/get-items`, { userID }, { withCredentials: true });
            const result = response.data;

            setTotalAmount(result.totalAmount);
            setDiscounted(result.discounted);
            setCartItems(result.items);
            setEmptyCart(result.items.length === 0)
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('An error occurred while fetching cart items:', error);
            }
        }

    };

    const getAddress = async () => {
        try {
            const url = process.env.REACT_APP_BASE_URL + `/address/get-user-address`;
            const response = await axios.post(url, { userID }, { withCredentials: true });

            setAddress(response.data.address);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login');
            } else if (error.request) {
                console.error('Error making request:', error.message);
                navigate('/error');
            } else {
                console.error('Error:', error.message);
            }
            return [];
        } finally {
            setLoading(false)
        }
    }

    const handleOrder = async (paymentID) => {
        if (selectedAddressID !== '') {
            setIsPlacingOrder(true);
            const orderID = generateOrderID();
            try {
                await axios.post(process.env.REACT_APP_BASE_URL + '/checkout/place-order', {
                    userID: userID,
                    orderID: orderID,
                    amount: totalAmount,
                    addressID: selectedAddressID,
                    payment: paymentID
                }, { withCredentials: true })
                navigate('/checkout/success')
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                } else if (error.request) {
                    console.error('Error making request:', error.message);
                    navigate('/error');
                } else {
                    console.error('Error:', error.message);
                }
            } finally {
                setIsPlacingOrder(false);
            }
        } else {
            setSnackbarOpen(true);
        }
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleAddAddressClick = () => {
        navigate('/manage-address/add');
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <ShoppingCartCheckout style={{ marginRight: 6 }} /><Typography variant="h6">Checkout</Typography>
                </Toolbar>
            </AppBar>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (<Container style={{ marginTop: 8 }}>

                {emptyCart ? (
                    <Grid container direction="column" alignItems="center" justify="center" spacing={2} style={{ marginTop: 8, backgroundColor: 'white', paddingBottom: 24 }}>
                        <Grid item>
                            <RemoveShoppingCartTwoTone style={{ fontSize: 100, color: 'gray' }} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h5">Kuch hai hi no :(</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" textAlign={'center'}>Your shopping cart is Empty.<br />Add some items and order now!</Typography>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => navigate('/')} startIcon={<AddShoppingCart />}>Moye Moye</Button>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>

                        {/* Address Section */}
                        <Grid item xs={12} style={{ backgroundColor: 'white', marginTop: 20, padding: 20 }}>
                            <Typography variant="h6" color="primary" style={{ marginBottom: 8, fontSize: '16px' }}>Select Delivery Address:</Typography>
                            <div style={{ overflowX: 'auto' }}>
                                <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                    {address && address.map((address) => (
                                        <Grid item key={address._id}>
                                            <AddressCard
                                                variant={selectedAddressID === address._id ? 'elevation' : 'outlined'}
                                                isSelected={selectedAddressID === address._id}
                                                onClick={() => {
                                                    setSelectedAddressID(address._id);
                                                    setSelectedName(address.fullName);
                                                    setSelectedPhone(address.phone);
                                                    setSelectedEmail(address.email);
                                                }}
                                            >
                                                <Typography variant="subtitle1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {address.fullName}
                                                    {selectedAddressID === address._id && (
                                                        <CheckCircleOutline color="primary" />
                                                    )}
                                                </Typography>
                                                <Typography variant="body2">{address.street}, {address.landmark}</Typography>
                                                <Typography variant="body2">{address.city}, {address.pinCode}</Typography>
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Typography variant="body2">Email: {address.email}</Typography>
                                                <Typography variant="body2">Phone: {address.phone}</Typography>
                                            </AddressCard>
                                        </Grid>
                                    ))}
                                    <Grid item>
                                        <Card
                                            variant={'outlined'}
                                            sx={{ cursor: 'pointer', maxWidth: 300 }}
                                            style={{ padding: 10 }}
                                            onClick={handleAddAddressClick}
                                        >
                                            <Button startIcon={<Add />}>Add Address</Button>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>


                        {/* Order Summary Section */}
                        <Grid item xs={12} style={{ backgroundColor: 'white', marginTop: 20, padding: 20 }}>
                            <Typography variant="h6" color="primary" style={{ fontSize: '16px' }}>Order Summary:</Typography>
                            <Grid container spacing={2} style={{ marginTop: 2 }}>
                                {cartItems.map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Card>
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item md={2} xs={3} style={{ textAlign: 'center' }}>
                                                        <img src={item.productID.images[0]} alt={item.productID.title} style={{ maxWidth: 80, maxHeight: 100 }} />
                                                    </Grid>
                                                    <Grid item md={10} xs={9} style={{ placeSelf: 'center' }}>
                                                        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>{item.productID.title}</Typography>
                                                        <Typography variant="body1" style={{ marginBottom: 6 }}>Size: {item.size} {item.color && `| Color: ${item.color}`} {item.quantity && `| Qty: ${item.quantity}`}</Typography>
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                                            <Typography variant='h6'>
                                                                ₹{item.productID.finalPrice * item.quantity}
                                                            </Typography>

                                                            <Typography variant="body2" style={{ marginLeft: '6px', textDecoration: 'line-through', color: 'grey', fontSize: '16px' }}>
                                                                ₹{item.productID.actualPrice * item.quantity}
                                                            </Typography>

                                                            <div style={{ marginLeft: '6px', backgroundColor: 'green', padding: '4px 6px', borderRadius: '4px', display: 'inline-block' }}>
                                                                <Typography variant="body1" style={{ color: 'white', fontSize: '12px' }}>
                                                                    {item.productID.discount}% off
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                            <Divider style={{ margin: '20px 0' }} />

                            <Grid container spacing={2} direction="column">
                                <Grid item container justifyContent="space-between">
                                    <Typography>Actual Price:</Typography>
                                    <Typography>₹{parseInt(totalAmount) + parseInt(discounted)}.00</Typography>
                                </Grid>
                                <Grid item container justifyContent="space-between">
                                    <Typography>Discount:</Typography>
                                    <Typography>- ₹{discounted}.00</Typography>
                                </Grid>
                                <Grid item container justifyContent="space-between">
                                    <Typography>Delivery Fee:</Typography>
                                    <Typography sx={{ color: 'green' }}>FREE (₹50.00)</Typography>
                                </Grid>
                                <Grid item container justifyContent="space-between">
                                    <Typography>Total Amount:</Typography>
                                    <Typography>₹{totalAmount}.00</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ backgroundColor: 'white', marginTop: 20, marginBottom: 20, padding: 20 }}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={6}>
                                    <Typography variant='h6'>Select Payment Method</Typography>
                                </Grid>
                                <Grid item xs={6} container justifyContent="flex-end">
                                    <Button variant="contained" style={{ marginRight: 10, textTransform: 'none' }} onClick={() => handleOrder('COD')}>
                                        Cash on Delivery (COD)
                                    </Button>
                                    {/* <Button variant="contained" color="primary" style={{ textTransform: 'none' }} onClick={() => console.log('Pay Now')}>
                                        Pay Online via RazorPay
                                    </Button> */}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Dialog
                            open={isPlacingOrder}
                            PaperProps={{
                                style: {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            <div style={{ backgroundColor: 'white', padding: 24, textAlign: 'center' }}>
                                <CircularProgress />
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: 16 }}>
                                    Your order is getting placed...
                                </Typography>
                                <Typography variant="body2" style={{ marginTop: 8, textAlign: 'center' }}>
                                    Do not exit or close the application.
                                </Typography>
                            </div>
                        </Dialog>

                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackbar}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        >
                            <Alert
                                elevation={6}
                                variant="filled"
                                onClose={handleCloseSnackbar}
                                severity="primary"
                                icon={<Warning />}
                            >
                                Please select an address before placing the order.
                            </Alert>
                        </Snackbar>

                    </Grid>)}

            </Container>)}
        </div >

    );
};

export default CheckoutScreen;
