import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Card, CardContent, Grid, Button, Box, CircularProgress, CardMedia, CardActions } from '@mui/material';
import axios from 'axios';
import Cookies from 'universal-cookie';
import NavBar from '../../components/navbar';
import { AddShoppingCart, Delete, Favorite } from '@mui/icons-material';

function WishlistScreen() {
    const [empty, setEmpty] = useState(false);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getWishlist();
    }, []);

    const getWishlist = async () => {
        try {
            const cookies = new Cookies();
            const userID = cookies.get('userID');
            const response = await axios.post(process.env.REACT_APP_BASE_URL + `/wishlist/get-items`, { userID }, { withCredentials: true });
            const result = response.data;

            setProducts(result.items);
            setEmpty(result.items.length === 0);
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('An error occurred while fetching cart items:', error);
            }
        } finally {
            setLoading(false)
        }
    };

    const removeItemFromWishlist = async (wishlistID) => {
        try {
            await axios.delete(process.env.REACT_APP_BASE_URL + `/wishlist/remove-item`, {
                data: { wishlistID },
                withCredentials: true
            });

            getWishlist();

        } catch (error) {
            console.error('An error occurred while removing item from wishlist:', error);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <Favorite style={{ marginRight: 6 }} /><Typography variant="h6">Your Shopping List</Typography>
                </Toolbar>
            </AppBar>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Container>
                    {empty ? (
                        <Grid container direction="column" alignItems="center" justify="center" spacing={2} style={{ marginTop: 8, backgroundColor: 'white', paddingBottom: 24 }}>
                            <Grid item>
                                <Favorite style={{ fontSize: 100, color: 'gray' }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Your shopping list is empty</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" textAlign={'center'}>You have no items in your list.<br />Add some items and to buy them later!</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={() => navigate('/')} startIcon={<AddShoppingCart />}>Moye Moye</Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <div>

                            <Grid container spacing={2} style={{ padding: '10px' }}>
                                {products.map(product => (
                                    <Grid key={product.productID._id} item xs={6} sm={6} md={4} lg={3}>
                                        <Card>
                                            <Link to={'/product?id=' + product.productID._id} style={{}}>
                                                <CardMedia
                                                    component="img"
                                                    height="300"
                                                    image={product.productID.images[0]}
                                                    alt={product.productID.title}
                                                    style={{ objectFit: 'cover' }}
                                                />

                                                <CardContent style={{ paddingBottom: 8 }}>
                                                    <Typography variant="subtitle1" component="div" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                        {product.productID.title}
                                                    </Typography>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography variant="body1" style={{ color: 'black', fontSize: '18px' }}>
                                                            ₹{product.productID.finalPrice}
                                                        </Typography>

                                                        <Typography variant="body2" style={{ marginLeft: '6px', textDecoration: 'line-through', color: 'grey', fontSize: '14px' }}>
                                                            ₹{product.productID.actualPrice}
                                                        </Typography>

                                                        <div style={{ marginLeft: '6px', backgroundColor: 'green', padding: '4px 6px', borderRadius: '4px', display: 'inline-block' }}>
                                                            <Typography variant="body1" style={{ color: 'white', fontSize: '12px' }}>
                                                                {product.productID.discount}% off
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </CardContent>

                                                <CardActions>
                                                    <Button variant="outlined" onClick={() => removeItemFromWishlist(product._id)} color="error">
                                                        <Delete /> Remove from Wishlist
                                                    </Button>
                                                </CardActions>
                                            </Link>
                                        </Card>

                                    </Grid>
                                ))}
                            </Grid>
                        </div>

                    )}
                </Container>
            )}
        </div>
    );
}

export default WishlistScreen;
