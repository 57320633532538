import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Card, CardContent, Grid, Button, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import Cookies from 'universal-cookie';
import NavBar from '../../components/navbar';
import { Delete, Edit, RateReview, Reviews, Star } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

const ReviewScreen = () => {
    const [empty, setEmpty] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reviews, setReviews] = useState([]);
    const navigate = useNavigate();
    const theme = useTheme()

    useEffect(() => {
        getReviews();
    }, []);

    const getReviews = async () => {
        try {
            const cookies = new Cookies();
            const userID = cookies.get('userID');

            const response = await axios.post(process.env.REACT_APP_BASE_URL + `/review/get-user-reviews`, { userID }, { withCredentials: true });
            const result = response.data.reviews;

            setReviews(result);
            setEmpty(result.length !== 0);

        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('An error occurred while fetching cart items:', error);
            }
        } finally {
            setLoading(false)
        }
    };

    const removeReview = async (reviewID) => {
        try {
            await axios.delete(process.env.REACT_APP_BASE_URL + `/review/delete-review`, {
                data: { reviewID },
                withCredentials: true
            });

            getReviews();

        } catch (error) {
            console.error('An error occurred while removing item from cart:', error);
        }
    };

    const handleEditReview = (review) => {
        navigate('/manage-reviews/edit', {
            state: { review }
        });
    };

    const RatingStars = ({ rating }) => {
        return (
            <Grid container>
                {[...Array(5)].map((_, index) => (
                    <Star color={index < rating ? "primary" : "grey"} />
                ))}
            </Grid>
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <Reviews style={{ marginRight: 6 }} /><Typography variant="h6">Your Reviews</Typography>
                </Toolbar>
            </AppBar>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (

                <Container>
                    {empty ? (
                        <div>

                            <Grid container spacing={2} style={{ marginTop: 8 }}>
                                {reviews.map((review, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Card>
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={2}>
                                                        <img src={review.productID.images[0]} alt={review.productID.title} style={{ maxWidth: 140, maxHeight: 160 }} />
                                                    </Grid>
                                                    <Grid item xs={8} style={{ placeSelf: 'center' }}>
                                                        <Typography variant="subtitle1" style={{ marginBottom: 2, color: theme.palette.lightColor.main }}>{review.productID.title}</Typography>
                                                        <RatingStars key={index} rating={review.rating} />
                                                        <Typography variant="h6">{review.title}</Typography>
                                                        <Typography variant="body1">{review.message}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} container justifyContent="flex-end" >
                                                        <Grid item sx={{ alignSelf: 'center' }}>
                                                            <Button color='info' startIcon={<Edit />} onClick={() => handleEditReview(review)}>Edit</Button>
                                                        </Grid>
                                                        <Grid item sx={{ alignSelf: 'center' }}>
                                                            <Button color='error' startIcon={<Delete />} onClick={() => removeReview(review._id)}>Delete</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                ))}
                            </Grid>
                        </div>
                    ) : (
                        <Grid container direction="column" alignItems="center" justify="center" spacing={2} style={{ marginTop: 8, backgroundColor: 'white', paddingBottom: 24 }}>
                            <Grid item>
                                <Reviews style={{ fontSize: 100, color: 'gray' }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">No Reviews</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" textAlign={'center'}>You have no reviews to view.<br />Add some when your order is delivered!</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={() => navigate('/orders')} startIcon={<RateReview />}>Start Reviewing</Button>
                            </Grid>
                        </Grid>
                    )}
                </Container>
            )}
        </div>
    );
}

export default ReviewScreen;
