import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Grid, MenuItem, Select, InputLabel, FormControl, Toolbar, AppBar, CircularProgress, Container } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Edit, EditLocation, LocationOn } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NavBar from '../../components/navbar';
import { fromLatLng, setKey } from 'react-geocode';

function EditAddressScreen() {
    const navigate = useNavigate();

    const location = useLocation();
    const { address } = location.state || {};

    const [selectedType, setSelectedType] = useState('Home');
    const [fetchingLocation, setFetchingLocation] = useState(false);

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            phone: '',
            street: '',
            landmark: '',
            city: '',
            pinCode: '',
        },
        validationSchema: Yup.object({
            fullName: Yup.string()
                .required('Full name is required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Email Address is required'),
            phone: Yup.string()
                .matches(/^\d{10}$/, 'Invalid phone number')
                .required('Phone number is required'),
            street: Yup.string()
                .required('Street address is required'),
            landmark: Yup.string(),
            city: Yup.string()
                .required('City is required'),
            pinCode: Yup.string()
                .matches(/^\d{6}$/, 'Invalid pincode')
                .required('Pincode is required'),
        }),
        onSubmit: values => {
            editAddress(values);
        },
    });

    useEffect(() => {
        formik.setValues({
            fullName: address.fullName,
            email: address.email,
            phone: address.phone,
            street: address.street,
            landmark: address.landmark,
            city: address.city,
            pinCode: address.pinCode,
        });

    }, []);

    const editAddress = async (data) => {
        try {
            await axios.post(
                process.env.REACT_APP_BASE_URL + '/address/edit-address',
                { ...data, type: selectedType, addressID: address._id },
                { withCredentials: true }
            );
            navigate('/manage-address');
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('Error adding new address:', error);
            }
        }
    };

    const detectLocation = async () => {
        setFetchingLocation(true);
        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            const { latitude, longitude } = position.coords;

            setKey(process.env.REACT_APP_API_KEY);

            fromLatLng(latitude.toString(), longitude.toString()).then(
                (response) => {
                    const address = response.results[0].address_components;
                    const { city, landmark, postal } = address.reduce(
                        (acc, component) => {
                            if (component.types.includes("locality"))
                                acc.city = component.long_name;
                            else if (component.types.includes("sublocality_level_2"))
                                acc.landmark = component.long_name;
                            else if (component.types.includes("postal_code"))
                                acc.postal = component.long_name;
                            return acc;
                        },
                        {}
                    );

                    const currentValues = { ...formik.values };
                    formik.setValues({
                        ...currentValues,
                        street: address[0]?.long_name || '',
                        city: city,
                        landmark: landmark,
                        pinCode: postal
                    })
                },
                (error) => {
                    console.error('Error fetching address:', error);
                }
            );
        } catch (error) {
            console.error('Error detecting location:', error);
        }
        setFetchingLocation(false);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <EditLocation style={{ marginRight: 6 }} /><Typography variant="h6">Edit Address</Typography>
                </Toolbar>
            </AppBar>

            <Container p={4} style={{ backgroundColor: 'white', marginTop: 12, paddingBottom: 12 }}>
                <Typography variant="h6" gutterBottom style={{ marginTop: 8, marginBottom: 8, fontSize: '15px' }}>
                    Address Details
                </Typography>

                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="fullName"
                                name="fullName"
                                label="Full Name"
                                value={formik.values.fullName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                                helperText={formik.touched.fullName && formik.errors.fullName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label="Email Address"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="phone"
                                name="phone"
                                label="Mobile Number"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ alignSelf: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={detectLocation}
                                startIcon={<LocationOn />}
                            >
                                {fetchingLocation ? <CircularProgress size={24} /> : 'Detect Location'}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="street"
                                name="street"
                                label="Street Address"
                                value={formik.values.street}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.street && Boolean(formik.errors.street)}
                                helperText={formik.touched.street && formik.errors.street}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="landmark"
                                name="landmark"
                                label="Nearby Landmark"
                                value={formik.values.landmark}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="city"
                                name="city"
                                label="City"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.city && Boolean(formik.errors.city)}
                                helperText={formik.touched.city && formik.errors.city}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="pincode"
                                name="pinCode"
                                label="Pincode"
                                value={formik.values.pinCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
                                helperText={formik.touched.pinCode && formik.errors.pinCode}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="address-type-label">Address type</InputLabel>
                                <Select
                                    label="Address type"
                                    value={selectedType}
                                    onChange={(e) => setSelectedType(e.target.value)}
                                >
                                    <MenuItem value="Home">Home</MenuItem>
                                    <MenuItem value="Office">Office</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={formik.isSubmitting}
                                startIcon={<Edit />}
                            >
                                Edit Address
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
}

export default EditAddressScreen;
