import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Button, Card, AppBar, Toolbar, Typography, Container, Grid } from '@mui/material';
import { Add, AddLocation, Delete, Edit, LocationOff } from '@mui/icons-material';
import Cookies from 'universal-cookie';
import axios from 'axios';
import NavBar from '../../components/navbar';
import { useTheme } from '@emotion/react';

function AddressScreen() {
    const navigate = useNavigate();
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchAddresses();
    }, []);

    const fetchAddresses = async () => {
        try {
            const cookies = new Cookies();
            const userID = cookies.get('userID');
            const response = await axios.post(process.env.REACT_APP_BASE_URL + '/address/get-user-address', { userID }, {
                withCredentials: true
            });
            setAddresses(response.data.address);
            setLoading(false);
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('An error occurred while fetching cart items:', error);
            }
        }
    };

    const handleEditAddress = (address) => {
        navigate('/manage-address/edit',
            {
                state: { address },
            });
    };

    const handleDeleteAddress = async (addressID) => {
        try {
            await axios.delete(process.env.REACT_APP_BASE_URL + '/address/delete', {
                data: { addressID },
                withCredentials: true
            });
            fetchAddresses();
        } catch (error) {
            console.error('Error deleting address:', error);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <AddLocation style={{ marginRight: 6 }} /><Typography variant="h6">Manage Address</Typography>
                </Toolbar>
            </AppBar>

            <Container style={{ backgroundColor: 'white', marginTop: 12, textAlign: 'center' }} onClick={() => navigate('/manage-address/add')}>
                <Button startIcon={<Add />}>
                    Add New Address
                </Button>
            </Container>

            {loading ? (
                <Container style={{ textAlign: 'center', marginTop: 12 }}>
                    <CircularProgress />
                </Container>

            ) : (
                <Container>
                    {addresses.length === 0 ? (
                        <Grid container direction="column" alignItems="center" justify="center" spacing={2} style={{ marginTop: 8, backgroundColor: 'white', paddingBottom: 24 }}>
                            <Grid item>
                                <LocationOff style={{ fontSize: 100, color: 'gray' }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Aap kahan ho?</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" textAlign={'center'}>We don't know where should we deliver this package?<br />Add your address to order products.</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={() => navigate('/')} startIcon={<AddLocation />}>Mein nahi bataungaaa!</Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <div>
                            {addresses && addresses.map((address, index) => (
                                <AddressCard
                                    key={index}
                                    address={address}
                                    onEdit={() => handleEditAddress(address)}
                                    onDelete={() => handleDeleteAddress(address._id)}
                                />
                            ))}

                        </div>
                    )}
                </Container>
            )}

        </div>
    );
}

function AddressCard({ address, onEdit, onDelete }) {
    const theme = useTheme();

    return (
        <Card style={{ marginTop: 12, padding: 10 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ marginRight: '8px', fontSize: '18px' }}>{address.fullName}</Typography>
                            <Typography style={{ backgroundColor: theme.palette.primary.main, color: 'white', paddingBottom: 2, paddingTop: 2, paddingLeft: 4, paddingRight: 4 }}>{address.type}</Typography>
                        </div>
                        <p>{`${address.street}${address.landmark ? ', ' + address.landmark : ''}`}</p>
                        <p>{`${address.city}, ${address.pinCode}`}</p>
                        <p>{`${address.email} | ${address.phone}`}</p>
                    </div>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                        <Button color='info' startIcon={<Edit />} onClick={onEdit}>Edit</Button>
                    </Grid>
                    <Grid item>
                        <Button color='error' startIcon={<Delete />} onClick={onDelete}>Delete</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}

export default AddressScreen;
