import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import styled from '@emotion/styled';
import NavBar from '../../components/navbar';
import { Box, Button, CircularProgress, Container, Grid, IconButton, Menu, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Cancel, ContentCopy, CopyAll, Favorite, IosShare, LocalMall, Share, ShoppingBag, ShoppingBasket, ShoppingCart, WhatsApp } from '@mui/icons-material';
import Footer from '../../components/footer';
import CustomDialog from '../../components/dialog';
import Cookies from 'universal-cookie';

const DetailsContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
  margin-left: 20px;

  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  margin-top: 8px;
  margin-bottom: 12px;
`;

const Price = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 400;
  margin-top: 6px;
`;

const SizeColorContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const SizeColorTitle = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
`;

const SizeColorList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SizeColorItem = styled('label')(({ theme, isSelected, disabled }) => ({
  display: 'inline-block',
  marginRight: '10px',
  padding: '10px',
  border: '2px solid #eee',
  borderRadius: '8px',
  cursor: disabled ? 'not-allowed' : 'pointer',
  color: isSelected ? '#fff' : '#000',
  backgroundColor: isSelected ? theme.palette.primary.main : (disabled ? '#ff0000' : '#fff'),
  fontWeight: isSelected ? 'bold' : 'normal',
  minWidth: '30px',
  textAlign: 'center',
  opacity: disabled ? 0.65 : 1,

  '&:hover': {
    borderColor: disabled ? null : '#000',
  },
}));

const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ProductImages = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
`;

const PrevButton = styled(NavigationButton)`
  left: 10px;
`;

const NextButton = styled(NavigationButton)`
  right: 10px;
`;

const Thumbnail = styled.img`
  width: 30px;
  height: auto;
  margin-right: 10px;
  cursor: pointer;
  border: ${({ active }) => (active ? '2px solid #007bff' : '2px solid transparent')};
`;

const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const ProductPage = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [product, setProduct] = useState(null);
  const [colorList, setColorList] = useState([]);

  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSizeTitle, setSelectedSizeTitle] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [dialogButtonText, setDialogButtonText] = useState('');
  const [dialogShowBtn, setDialogShowBtn] = useState(false);
  const [dialogIcon, setDialogIcon] = useState(null);
  const [dialogCircleColor, setDialogCircleColor] = useState('');
  const [dialogNavigateTo, setDialogNavigateTo] = useState(null);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleOptionClick = (option) => {
    setAnchorEl(null);
    const productUrl = window.location.href;
    if (option === 'whatsapp') {
      const whatsappMessage = `Check out this product: ${product.title}\n${productUrl}`;
      const encodedMessage = encodeURIComponent(whatsappMessage);
      const whatsappUrl = `whatsapp://send?text=${encodedMessage}`;
      window.location.href = whatsappUrl;
    } else if (option === 'copy') {
      navigator.clipboard.writeText(productUrl);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSelectSize = (sizeTitle, disabled) => {
    if (disabled) {
      setDialogTitle('Sold Out')
      setDialogContent('Size is currently sold out')
      setDialogCircleColor('red')
      setDialogIcon(<Cancel color='secondary' />)
      setDialogShowBtn(false);
      setDialogOpen(true);
    } else {
      setSelectedSizeTitle(sizeTitle);
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, product.images.length - 1));
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const handleAddtoCart = async () => {
    const cookies = new Cookies();
    var isLogin = cookies.get("userID") ? true : false;
    if (isLogin) {
      if (selectedSizeTitle != null) {
        try {
          const userID = cookies.get("userID");

          const data = {
            productID: id,
            userID: userID,
            size: selectedSizeTitle,
            color: selectedColor
          };

          const response = await axios.post(process.env.REACT_APP_BASE_URL + '/cart/add-product-to-cart', data, {
            withCredentials: true
          });

          const result = response.data;

          if (result.already) {
            setDialogTitle('Ready for checkout');
            setDialogButtonText('Open Cart');
            setDialogCircleColor('#0f2649');
            setDialogShowBtn(true)
            setDialogIcon(<ShoppingBag color='secondary' />);
            setDialogNavigateTo('/cart');
            setDialogContent("Product is already in your cart");
            setDialogOpen(true);
          } else {
            setDialogTitle('Added to Cart');
            setDialogButtonText('Open Cart');
            setDialogCircleColor('#0f2649');
            setDialogShowBtn(true)
            setDialogIcon(<ShoppingBag color='secondary' />);
            setDialogContent("Ek product, Bag mein gaya, Chapak..!");
            setDialogNavigateTo('/cart');
            setDialogOpen(true);
          }
        } catch (error) {
          if (error.response.status === 401) {
            navigate('/login')
          } else {
            console.error(error)
          }
        }
      } else {
        setDialogTitle('Select a Size');
        setDialogShowBtn(false);
        setDialogCircleColor('#0f2649');
        setDialogIcon(<LocalMall color='secondary' />);
        setDialogContent("Oh, What size should we send?");
        setDialogOpen(true);
      }

    } else {
      navigate('/login')
    }

  }

  const handleAddtoWishlist = async () => {
    const cookies = new Cookies();
    var isLogin = cookies.get("userID") ? true : false;
    if (isLogin) {

      try {
        const userID = cookies.get("userID");

        const data = {
          productID: id,
          userID: userID
        };

        const response = await axios.post(process.env.REACT_APP_BASE_URL + '/wishlist/add-product-to-wishlist', data, {
          withCredentials: true
        });

        const result = response.data;

        if (result.already) {
          setDialogTitle('Ready for checkout');
          setDialogButtonText('Open Wishlist');
          setDialogCircleColor('#0f2649');
          setDialogShowBtn(true)
          setDialogIcon(<Favorite color='secondary' />);
          setDialogNavigateTo('/wishlist');
          setDialogContent("Product is already in your wishlist");
          setDialogOpen(true);
        } else {
          setDialogTitle('Wishlisted');
          setDialogButtonText('Open Wishlist');
          setDialogCircleColor('#0f2649');
          setDialogShowBtn(true)
          setDialogIcon(<Favorite color='secondary' />);
          setDialogContent("Ek product, Wishlist mein gaya, Chapak..!");
          setDialogNavigateTo('/wishlist');
          setDialogOpen(true);
        }
      } catch (error) {
        if (error.response.status === 401) {
          navigate('/login')
        } else {
          console.error(error)
        }
      }
    } else {
      navigate('/login')
    }

  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_BASE_URL + '/product/get-by-prodID', {
          prodID: id,
        });
        setProduct(response.data.product);
        if (response.data.product.colors.includes(',')) {
          setColorList(response.data.product.colors.split(', '));
        } else {
          setColorList([response.data.product.colors]);
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (currentIndex >= 0 && currentIndex < colorList.length) {
      setSelectedColor(colorList[currentIndex]);
    } else {
      setSelectedColor(colorList[0]);
    }
  }, [colorList, currentIndex]);

  if (!product) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }


  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

      <NavBar />

      <Container>
        <ProductContainer style={{ flexGrow: 1, backgroundColor: 'white', overflowY: 'auto', marginTop: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ImageContainer>
                <ProductImages>
                  <Image src={product.images[currentIndex]} alt={`Product Image ${currentIndex + 1}`} />
                  <PrevButton onClick={handlePrevClick}>←</PrevButton>
                  <NextButton onClick={handleNextClick}>→</NextButton>
                </ProductImages>
                <ThumbnailContainer>
                  {product.images.map((imageURL, index) => (
                    <Thumbnail
                      key={index}
                      src={product.images[index]}
                      alt={`Thumbnail ${index + 1}`}
                      active={index === currentIndex}
                      onClick={() => handleThumbnailClick(index)}
                    />
                  ))}
                </ThumbnailContainer>
              </ImageContainer>
            </Grid>

            <Grid item xs={12} md={6}>
              <DetailsContainer>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={10} lg={10}>
                    <Title>{product.title}</Title>
                  </Grid>
                  <Grid item xs={2} lg={2}>
                    <IconButton
                      aria-label="Share"
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                      <IosShare />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem onClick={() => handleOptionClick('whatsapp')}>
                        <WhatsApp /> Share on WhatsApp
                      </MenuItem>
                      <MenuItem onClick={() => handleOptionClick('copy')}>
                        <CopyAll /> Copy Link
                      </MenuItem>
                    </Menu>
                    <Snackbar
                      open={snackbarOpen}
                      autoHideDuration={3000}
                      onClose={handleSnackbarClose}
                      message="Link copied to clipboard!"
                    />
                  </Grid>

                </Grid>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Price>
                    ₹{product.finalPrice}
                  </Price>

                  <Typography variant="body2" style={{ marginLeft: '6px', textDecoration: 'line-through', color: 'grey', fontSize: '16px' }}>
                    ₹{product.actualPrice}
                  </Typography>

                  <div style={{ marginLeft: '6px', backgroundColor: 'green', padding: '4px 6px', borderRadius: '4px', display: 'inline-block' }}>
                    <Typography variant="body1" style={{ color: 'white', fontSize: '12px' }}>
                      {product.discount}% off
                    </Typography>
                  </div>
                </div>

                <Description>{product.description}</Description>

                <SizeColorContainer>
                  <SizeColorTitle>Color: {selectedColor}</SizeColorTitle>
                </SizeColorContainer>

                <SizeColorContainer>
                  <SizeColorTitle>Available Sizes:</SizeColorTitle>
                  <SizeColorList>
                    {product.sizes.map((sizeItem, index) => {
                      const isDisabled = sizeItem.quantity.find((element) => element.color === selectedColor)?.qty <= 0;
                      const isSelected = !isDisabled && selectedSizeTitle === sizeItem.sizeTitle;

                      return (
                        <SizeColorItem
                          key={index}
                          isSelected={isSelected}
                          onClick={() => handleSelectSize(sizeItem.sizeTitle, isDisabled)}
                          disabled={isDisabled}
                        >
                          {sizeItem.sizeTitle}
                        </SizeColorItem>
                      );
                    })}
                  </SizeColorList>
                </SizeColorContainer>

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <Button
                      variant="outlined"
                      onClick={handleAddtoWishlist}
                      color="primary"
                      startIcon={<ShoppingBasket />}
                      fullWidth
                    >
                      Wishlist
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Button
                      variant="contained"
                      onClick={handleAddtoCart}
                      color="primary"
                      startIcon={<ShoppingCart />}
                      fullWidth

                    >
                      Add to Cart
                    </Button>
                  </Grid>
                </Grid>

                <TableContainer style={{ marginTop: 24 }}>
                  <Typography variant='body1' style={{ marginBottom: 8 }}>Product Features:</Typography>
                  <Table sx={{ border: '1px solid #dddddd' }}>
                    <TableBody>
                      {product.features.map((feature, index) => (
                        <TableRow key={index}>
                          <TableCell>{feature.key}</TableCell>
                          <TableCell>{feature.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DetailsContainer>
            </Grid>
          </Grid>
        </ProductContainer>

        <CustomDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          title={dialogTitle}
          buttonText={dialogButtonText}
          showBtn={dialogShowBtn}
          description={dialogContent}
          circleColor={dialogCircleColor}
          icon={dialogIcon}
          navigateTo={dialogNavigateTo}
        />
      </Container>
      <Footer />
    </div>
  );
};

export default ProductPage;
