import React, { useEffect, useRef, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { AppBar, Box, CircularProgress, Container, Grid, Input, InputBase, Toolbar, alpha } from "@mui/material";
import { useTheme } from "@emotion/react";
import { HourglassEmpty } from "@mui/icons-material";
import styled from "@emotion/styled";
import ProductCard from "../../components/product_card";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SearchPage = () => {
    const [searchTerm, setSearchTerm] = useState('')
    const [products, setProducts] = useState([])
    const [searching, setSearching] = useState(false)

    const theme = useTheme()
    const navigate = useNavigate();

    const inputRef = useRef(null);

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha("#FFFFFF", 0.25),
        '&:hover': {
            backgroundColor: alpha("#FFFFFF", 0.25),
        },
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    }));

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(Input)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            [theme.breakpoints.up('xl')]: {
                width: '80ch',
            },
            [theme.breakpoints.down('xl')]: {
                width: '60ch',
            },
            [theme.breakpoints.down('lg')]: {
                width: '40ch',
            },
        },
    }));

    const WidgetPreview = ({ icon, text, message }) => {
        return (
            <Container style={{ backgroundColor: 'white', marginTop: 12, padding: 8, textAlign: 'center' }}>
                <div>
                    {icon}
                </div>
                <div style={{ fontSize: 24, fontWeight: 'bold', marginTop: 8, color: theme.palette.primary.main }}>
                    {text}
                </div>
                <div>
                    <p style={{ fontSize: 16 }}>{message}</p>
                </div>
            </Container>
        );
    };

    useEffect(() => {
        inputRef.current.focus();
    }, [])

    useEffect(() => {
        setSearching(true)
        const search = async () => {
            try {
                const response = await axios.post(process.env.REACT_APP_BASE_URL + '/product/search', {
                    value: searchTerm,
                });
                setProducts(response.data.products)
            } catch (error) {
                console.error('An error occurred while fetching cart items:', error);
            } finally {
                setSearching(false)
            }
        }
        search();

    }, [searchTerm])

    return (
        <div>

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <Grid container spacing={2} alignItems="center" style={{ marginBottom: 4 }}>
                        <Grid item xs={12} sm={2} md={2} lg={2} style={{ textAlign: 'center' }}>
                            <img
                                src="/line-logo-large.png"
                                height={70}
                                alt="Allusion"
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate('/')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8} style={{ marginLeft: 10, marginRight: 10 }} sx={{ paddingTop: { xs: '0px !important', md: '16px !important' }, marginBottom: {xs: 2, md: 0 } }}>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search for products"
                                    ref={inputRef}
                                    autoFocus
                                    inputProps={{ 'aria-label': 'search' }}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Search>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>

            {
                searchTerm !== '' ? (
                    searching ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Container>
                            {products.length > 0 ? (
                                <Grid container spacing={2} style={{ padding: '10px' }}>
                                    {products.map(product => (
                                        <Grid key={product.id} item xs={6} sm={6} md={4} lg={3}>
                                            <ProductCard product={product} />
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <WidgetPreview icon={<HourglassEmpty style={{ fontSize: 80, color: theme.palette.primary.main }} />} text={'Aayien...?'} message={'Moye, Moye..!\nWe could not find any product.\nTry searching for something else!'} />
                            )}
                        </Container>
                    )
                ) : (
                    <WidgetPreview icon={<SearchIcon style={{ fontSize: 80, color: theme.palette.primary.main }} />} text={'Aap kya chahte ho?'} message={'Kurta, T-Shirt, Attar...?'} />
                )
            }
        </div>
    )
}

export default SearchPage;