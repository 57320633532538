import { EditNote, Star, Warning } from "@mui/icons-material";
import { Alert, AppBar, Button, Card, CardContent, Container, Divider, Grid, IconButton, Snackbar, TextField, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../components/navbar";
import axios from "axios";

const EditReview = () => {
    const location = useLocation();
    const { review } = location.state || {};

    const [title, setTitle] = useState(review.title);
    const [message, setMessage] = useState(review.message);
    const [rating, setRating] = useState(review.rating);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const navigate = useNavigate();

    const onStarClick = (star) => {
        setRating(star);
    };

    const RatingStars = () => {
        return (
            <Grid container justifyContent="center">
                {[...Array(5)].map((_, index) => (
                    <IconButton key={index} onClick={() => onStarClick(index + 1)}>
                        <Star sx={{ fontSize: 28 }} color={index < rating ? "primary" : "grey"} />
                    </IconButton>
                ))}
            </Grid>
        );
    };

    const handleSubmit = async () => {

        if (rating > 0 && title.trim() !== '' && message.trim() !== '') {

            try {
                await axios.post(process.env.REACT_APP_BASE_URL + `/review/edit-review`, {
                    reviewID: review._id,
                    rating: rating.toString(),
                    title: title,
                    message: message
                }, { withCredentials: true });
                navigate('/manage-reviews')
            } catch (error) {
                if (error.response.status === 401) {
                    navigate('/login')
                } else {
                    console.error('An error occurred while fetching cart items:', error);
                }
            }
        } else {
            setSnackbarOpen(true)
            setSnackbarMessage('Please fill in all fields and select a rating');
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <EditNote style={{ marginRight: 6 }} /><Typography variant="h6">Edit Review</Typography>
                </Toolbar>
            </AppBar>

            <Container>
                <Card elevation={8} sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <Typography variant="subtitle1">Submit Review</Typography>
                        <Divider style={{ margin: '8px 0' }} />
                        <RatingStars />
                        <TextField
                            label="Review Title"
                            variant="outlined"
                            fullWidth
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            label="Review Message"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            margin="normal"
                        />
                        <Container sx={{ textAlign: 'center' }}>
                            <Button variant="contained" color="primary" sx={{ marginTop: 1 }} onClick={handleSubmit}>Submit Review</Button>
                        </Container>

                    </CardContent>
                </Card>
            </Container>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => setSnackbarOpen(false)}
                    severity="primary"
                    icon={<Warning />}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default EditReview;