import React, { useState } from 'react';
import { AppBar, Button, Container, Grid, TextField, Toolbar, Typography, Snackbar, Alert, CircularProgress, Box } from '@mui/material';
import NavBar from '../../components/navbar';
import { ContactSupportRounded, LineAxis, MailOutline, Warning } from '@mui/icons-material';
import Footer from '../../components/footer';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';

const ContactUsPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        // Validation checks
        if (!email || !name || !subject || !message) {
            setSnackBarSeverity('error');
            setSnackBarMessage('Please fill in all fields!');
            setOpenSnackBar(true);
            setIsLoading(false);
            return;
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setSnackBarSeverity('error');
            setSnackBarMessage('Please enter a valid email address!');
            setOpenSnackBar(true);
            setIsLoading(false);
            return;
        }

        try {
            const sanitizedName = DOMPurify.sanitize(name);
            const sanitizedEmail = DOMPurify.sanitize(email);
            const sanitizedSubject = DOMPurify.sanitize(subject);
            const sanitizedMessage = DOMPurify.sanitize(message);

            // Send sanitized data to backend
            await axios.post(process.env.REACT_APP_BASE_URL + '/website/new-query', {
                name: sanitizedName,
                email: sanitizedEmail,
                subject: sanitizedSubject,
                message: sanitizedMessage,
            });

            // Clear form fields after successful submission
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
            setSnackBarSeverity('success');
            setSnackBarMessage('Query submitted successfully!');
            setOpenSnackBar(true);
        } catch (error) {
            console.error('Error submitting query:', error);
            setSnackBarSeverity('error');
            setSnackBarMessage('Failed to submit query. Please try again later.');
            setOpenSnackBar(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginBottom: 12 }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <ContactSupportRounded style={{ marginRight: 6 }} /><Typography variant="h6">Contact Us</Typography>
                </Toolbar>
            </AppBar>

            <Container style={{ backgroundColor: 'white', marginTop: 8, padding: 12, textAlignLast: 'center' }}>
                <Typography variant="h6" gutterBottom style={{ marginTop: 8, marginBottom: 0, fontSize: '16px' }}>
                    Raise a Query
                </Typography>

                <Grid container style={{ placeContent: 'center' }}>
                    <Grid item xs={10} sm={8} md={6}>
                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            <TextField
                                id="name"
                                label="Full Name"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                id="email"
                                label="Email Address"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                id="subject"
                                label="Subject"
                                variant="outlined"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                id="message"
                                label="Message"
                                multiline
                                rows={4}
                                variant="outlined"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress size={24} /> : "Send"}
                            </Button>
                        </form>
                    </Grid>
                </Grid>

                <Box display="flex" style={{placeContent: 'center'}} alignItems="center" marginTop={4} border={1} borderColor="grey.500" borderRadius={4} padding={2}>
                    <MailOutline />
                    <Typography variant="body1" align='center' marginLeft={1}>
                        Write us at <Link href='mailto:contact@theallusionstore.com'><u>contact@theallusionstore.com</u></Link>
                    </Typography>
                </Box>
            </Container>

            <Footer />

            <Snackbar
                open={openSnackBar}
                autoHideDuration={6000}
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackBar}
                    severity={snackBarSeverity}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ContactUsPage;
