import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const ProductHorizontalScroll = ({ products }) => {
    const scrollContainerRef = useRef({});
    const [containerWidth, setContainerWidth] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        handleContainerResize();
    }, []);

    const handleContainerResize = () => {
        if (scrollContainerRef.current) {
            setContainerWidth(scrollContainerRef.current.clientWidth);
        }
    };

    const handleScrollLeft = () => {
        if (scrollContainerRef.current) {
            const newPosition = scrollPosition - containerWidth;
            const newPositionAdjusted = Math.max(newPosition, 0);
            setScrollPosition(newPositionAdjusted);
            scrollContainerRef.current.scrollTo({
                left: newPositionAdjusted,
                behavior: 'smooth',
            });
        }
    };

    const handleScrollRight = () => {
        if (scrollContainerRef.current) {
            const newPosition = scrollPosition + containerWidth;
            const maxScroll = scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth;
            const newPositionAdjusted = Math.min(newPosition, maxScroll);
            setScrollPosition(newPositionAdjusted);
            scrollContainerRef.current.scrollTo({
                left: newPositionAdjusted,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Container>
            <ScrollContainer ref={scrollContainerRef} onScroll={handleContainerResize}>
                {products && products.length > 0 ? (
                    products.map((product, index) => (
                        <ProductCard key={index}>
                            <Link to={'/product?id=' + product._id} style={{}}>
                                <CardMedia
                                    component="img"
                                    height="350"
                                    image={product.images[0]}
                                    alt={product.title}
                                    style={{ objectFit: 'contain' }}
                                />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant="subtitle1" component="div" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                        {product.title}
                                    </Typography>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body1" style={{ color: 'black', fontSize: '18px' }}>
                                            ₹{product.finalPrice}
                                        </Typography>

                                        <Typography variant="body2" style={{ marginLeft: '6px', textDecoration: 'line-through', color: 'grey', fontSize: '14px' }}>
                                            ₹{product.actualPrice}
                                        </Typography>

                                        <div style={{ marginLeft: '6px', backgroundColor: 'green', padding: '4px 6px', borderRadius: '4px', display: 'inline-block' }}>
                                            <Typography variant="body1" style={{ color: 'white', fontSize: '12px' }}>
                                                {product.discount}% off
                                            </Typography>
                                        </div>

                                    </div>
                                </CardContent>
                            </Link>
                        </ProductCard>
                    ))) : (
                    <p>No products available</p>
                )}
            </ScrollContainer>
            <ScrollButtonContainer>
                <IconButton onClick={handleScrollLeft} style={{ background: 'rgba(255, 255, 255, 1)' }}>
                    <ArrowLeft />
                </IconButton>
                <IconButton onClick={handleScrollRight} style={{ background: 'rgba(255, 255, 255, 1)' }}>
                    <ArrowRight />
                </IconButton>
            </ScrollButtonContainer>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
`;

const ScrollContainer = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #ccc transparent; /* For Firefox */
    &::-webkit-scrollbar {
        width: 10px; /* Width of the entire scrollbar */
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* Color of the tracking area */
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc; /* Color of the scroll thumb */
        border-radius: 5px; /* Radius of the scroll thumb */
        border: 2px solid transparent; /* Border around the scroll thumb */
    }
`;

const ProductCard = styled(Card)`
    flex: 0 0 auto;
    margin-right: 8px;
    width: 300px; /* Adjust the card width as needed */
`;

const ScrollButtonContainer = styled.div`
    position: absolute;
    margin-bottom: 50px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 16px;
`;

export default ProductHorizontalScroll;
