import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function ProductCard({ product }) {
    return (

        <Card>
            <Link to={'/product?id=' + product._id} style={{}}>
                <CardMedia
                    component="img"
                    sx={{height: { xs: "300", md: "350" }}}
                    image={product.images[0]}
                    alt={product.title}
                    style={{ objectFit: 'cover' }}
                />
                <CardContent style={{ paddingBottom: 8 }}>
                    <Typography variant="subtitle1" component="div" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {product.title}
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ color: 'black', fontSize: '18px' }}>
                            ₹{product.finalPrice}
                        </Typography>

                        <Typography variant="body2" style={{ marginLeft: '6px', textDecoration: 'line-through', color: 'grey', fontSize: '14px' }}>
                            ₹{product.actualPrice}
                        </Typography>

                        <div style={{ marginLeft: '6px', backgroundColor: 'green', padding: '4px 6px', borderRadius: '4px', display: 'inline-block' }}>
                            <Typography variant="body1" sx={{ color: 'white', fontSize: { xs: '10px', md: '12px'}  }}>
                                {product.discount}% off
                            </Typography>
                        </div>

                    </div>
                </CardContent>
            </Link>
        </Card>
    );
}
export default ProductCard;