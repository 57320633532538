import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, DialogContentText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const StyledDialog = styled(Dialog)`
    border-radius: 16px;
    background-color: transparent;
    `;

const StyledDialogContent = styled(DialogContent)`
    padding: 32px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    `;

const Title = styled(Typography)`
    font-size: 22px;
    color: ${props => props.theme.palette.primary.main};
    font-weight: 500;
    text-align: center;
`;

const StyledButton = styled(Button)`
    background-color: ${props => props.theme.palette.primary.main};
    padding: 12px 60px;
    color: white;

    &:hover {
        background-color: transparent;
        border: 2px solid ${props => props.theme.palette.primary.main};
        color: ${props => props.theme.palette.primary.main};
    }    
`;

const Circle = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${props => props.color};
    margin-right: 10px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.75;
`;

const CustomDialog = ({ open, onClose, title, buttonText, navigateTo, showBtn, description, circleColor, icon }) => {
    const navigate = useNavigate();

    return (
        <StyledDialog open={open} onClose={onClose}>
            <StyledDialogContent>
                <DialogTitle style={{ textAlign: '-webkit-center' }}>
                    {circleColor && <Circle color={circleColor}>{icon}</Circle>}
                    <Title variant="h6">{title}</Title>
                    <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
                        <CloseIcon />
                    </IconButton>

                    <DialogContentText>
                        {description}
                    </DialogContentText>
                </DialogTitle>
                {showBtn && (
                    <DialogActions style={{ placeContent: 'center' }}>
                        <StyledButton onClick={() => navigate(navigateTo)} color="primary">{buttonText}</StyledButton>
                    </DialogActions>
                )}
            </StyledDialogContent>

        </StyledDialog>
    );
};

export default CustomDialog;
