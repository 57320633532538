import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Card, CardContent, Grid, Button, CircularProgress, Box } from '@mui/material';
import axios from 'axios';
import Cookies from 'universal-cookie';
import NavBar from '../../components/navbar';
import { AddShoppingCart, LocalShipping, LocalShippingTwoTone } from '@mui/icons-material';

function OrderScreen() {
    const [emptyOrders, setEmptyOrders] = useState(false);
    const [orders, setOrders] = useState([]);
    const [orderLoading, setOrderLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        getOrders();
    }, []);

    const getOrders = async () => {
        try {
            const cookies = new Cookies();
            const userID = cookies.get('userID');
            const response = await axios.post(process.env.REACT_APP_BASE_URL + `/orders`, { userID }, { withCredentials: true });
            const result = response.data;

            setOrders(result.items);
            setEmptyOrders(result.items.length === 0);
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/login')
            } else {
                console.error('An error occurred while fetching cart items:', error);
            }
        } finally {
            setOrderLoading(false)
        }
    };

    const getStatusMessage = (item) => {
        switch (item.status) {
            case 'Cancelled':
                return 'Cancelled by User';
            case 'Rejected':
                return 'Rejected by Store';
            case 'Refund':
                return 'Refund Completed';
            case 'Return':
                return 'Return Accepted';
            case 'Delivered':
                return 'Order Delivered';
            default:
                return `Delivery on ${item.deliveryDate}`;
        }
    };

    const handleOrderDetails = (order) => {
        navigate('/orders/detail',
            {
                state: { order },
            });
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <LocalShipping style={{ marginRight: 6 }} /><Typography variant="h6">Manage Orders</Typography>
                </Toolbar>
            </AppBar>

            {orderLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Container>
                    {emptyOrders ? (

                        <Grid container direction="column" alignItems="center" justify="center" spacing={2} style={{ marginTop: 8, backgroundColor: 'white', paddingBottom: 24 }}>
                            <Grid item>
                                <LocalShippingTwoTone style={{ fontSize: 100, color: 'gray' }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Kuch toh lele re baba!</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" textAlign={'center'}>You have not purchased anything yet.<br />Fill your cart and get an order placed!</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={() => navigate('/')} startIcon={<AddShoppingCart />}>Moye Moye</Button>
                            </Grid>
                        </Grid>

                    ) : (
                        <div>

                            <Grid container spacing={2} style={{ marginTop: 8 }}>
                                {orders.map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Card onClick={() => handleOrderDetails(item)}>
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                <Grid item xs={3} md={2} style={{alignSelf: 'center'}}>
                                                        <img src={item.productID.images[0]} alt={item.productID.title} style={{ maxWidth: '100%', maxHeight: 160 }} />
                                                    </Grid>
                                                    <Grid item xs={9} md={8} style={{ placeSelf: 'center' }}>
                                                        <Typography variant="body2">Order ID: {item.orderID}</Typography>
                                                        <Typography variant="h6" sx={{ fontSize: {xs: '18px', md: 'inherit'} }} style={{ marginBottom: 2 }}>{item.productID.title}</Typography>
                                                        <Typography variant="body1" sx={{ fontSize: {xs: '15px', lg: 'inherit'} }} style={{ marginBottom: 2 }}>Size: {item.size} {item.color && `| Color: ${item.color}`} {item.quantity && `| Qty: ${item.quantity}`}</Typography>

                                                        <Typography variant='h6' sx={{ fontSize: {xs: '18px'} }}>
                                                            {getStatusMessage(item)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>

                        </div>
                    )}
                </Container>
            )}
        </div>
    );
}

export default OrderScreen;
