import React from 'react';
import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import NavBar from '../../components/navbar';
import { Warning } from '@mui/icons-material';
import Footer from '../../components/footer';


const TermsPage = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginBottom: 12 }}>

            <NavBar />

            <AppBar position="static">
                <Toolbar style={{ placeSelf: 'center' }}>
                    <Warning style={{ marginRight: 6 }} /><Typography variant="h6">Terms & Condition</Typography>
                </Toolbar>
            </AppBar>

            <Container style={{ backgroundColor: 'white', marginTop: 8, padding: 12 }}>

                <p>Last updated: 24/10/2023</p>

                <h4>1. Acceptance of Terms</h4>
                <p>By using or accessing this app, you agree to be bound by these Terms and Conditions. If you do not
                    agree to these Terms and Conditions, please do not use our mobile application.</p>

                <h4>2. Privacy Policy</h4>
                <p>Our Privacy Policy explains how we collect, use, and protect your personal information when you use our
                    services. By using our mobile application, you agree to our Privacy Policy.</p>

                <h4>3. Ordering and Payment</h4>
                <p>When you place an order on our mobile application, you agree to provide accurate and up-to-date information. You are
                    responsible for payment of the items you order. We reserve the right to refuse or cancel orders at our
                    discretion.</p>

                <h4>5. Returns and Refunds</h4>
                <p>Our Return and Refund Policy outlines the conditions under which returns are accepted and refunds are
                    processed. Please refer to that policy for more information.</p>

                <h4>6. Intellectual Property</h4>
                <p>Our mobile application contains material that is owned or licensed to us. This material includes, but is not limited
                    to, the design, layout, graphics, and content. Reproduction of any part of our mobile application is prohibited unless
                    authorized by us.</p>

                <h4>7. Limitation of Liability</h4>
                <p>We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising from
                    your use of our mobile application, including but not limited to, loss of profits, data, or goodwill.</p>

                <h4>8. Changes to Terms and Conditions</h4>
                <p>We reserve the right to modify or replace these Terms and Conditions at any time. It is your responsibility
                    to check this page periodically for changes. Your continued use of our mobile application after any changes constitutes
                    acceptance of the new Terms and Conditions.</p>

            </Container>

            <Footer />
        </div>
    );
};

export default TermsPage;
